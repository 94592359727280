import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, InputGroupText, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody, ModalFooter,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';

export default class CortesProd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            version:1,
            popoverOpen:false,
            cargando:true,
            formVisible: false,
            producto:[],
            codigo:'000075',
            clave: 0,
            nombre:'?',
            corte:{},
            cortes: [],
            lineas:[],
            unidades:[]
        };
        this.cargarCatalogos();
        this.cargarCortes();
    }
    componentWillReceiveProps(newProps){
        this.setState({
            codigo:newProps.articulo.Codigo,
            producto:newProps.articulo,
        },()=>{
            this.cargarCortes();
        });
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCortes = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/prod/cortes?version=1&codigo=${this.state.codigo}`;
        const resp = await fetch(url);
        const cortes = await resp.json();
        this.setState({cargando:false, cortes });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/lineas/1`;
        let resp = await fetch(url);
        const lineas = await resp.json();
        url = `${this.props.urlws}/prod/unidadesTiempo`;
        resp = await fetch(url);
        const unidades = await resp.json();
        this.setState({cargando:false, lineas, unidades });
    }
    cargarProducto = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const producto = await resp.json();
        if(producto.Codigo === '' || producto.Depto === 1){
            Swal.fire('Atencion','Este codigo no corresponde a un producto o subproducto','error');
            this.setState({cargando:false});
            return;
        }
         this.setState({
            cargando:false,
            codigo:producto.Codigo,
            producto
        },() =>{
            if(producto.Id > 0)
                this.cargarCortes();
        });
    }
    onKeyPress = (e) =>{
        if(e.which == 13)
           this.cargarProducto(this.state.codigo);
    }
    togglePopOver = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    codigoProd = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarProducto(item.I);
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarCortes();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarCortes();
    }    
    cargarCorte = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/prod/cargarCorte/" + cve;
        const resp = await fetch(url);
        const corte = await resp.json();
        if(corte.Id == 0){
            corte.Codigo = this.state.codigo;
            corte.Version = this.state.version;
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            corte
        });
    }
    guardarCorte = async(corte) =>{
        const url = this.props.urlws +"/prod/wsguardarCorte";
        this.setState({cargando:true, corte});
        const json = JSON.stringify(corte);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
            this.cargarCortes();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoCorte = () => {
        this.cargarCorte(0);
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Cortes</NavbarBrand >
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <div className="col">
                                <Button size="sm" color="success" onClick={this.nuevoCorte}>+</Button>
                                &nbsp;
                                <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Aceptar</Button>
                            </div>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <CorteTable cortes={this.state.cortes} height={500} width={990} onRowClick={this.cargarCorte} />
                </div>
                <CorteForm urlws={this.props.urlws} visible={this.state.formVisible} lineas={this.state.lineas} unidades={this.state.unidades} corte={this.state.corte} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarCorte}/>
            </Container>
        );
    }
}

class CorteTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { cortes } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Codigo</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {cortes.map((esc, i) => (
                        <CorteRow key={i} corte={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class CorteRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.corte.I);
    }
    render(){
        let {corte} = this.props;
        return (
        <tr>
            <td>{corte.I}</td>
            <td>{corte.N}</td>
            <td>
                <Button color="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class CorteForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //articulo:this.props.articulo,
            produce:'',
            popoverOpen:false,
            popoverOpenP:false,
            corte:this.props.corte,
            NombreIns:'',
            producto:{},
            ...this.props.corte
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            corte:newProps.corte,
            ...newProps.corte
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const esc = {
            ...this.state.corte,
            [campo] : e.target.value
        };
        this.setState({corte:esc});
    }
    onChangeB = (e) => {
        const campo = e.target.name;
        this.setState({[campo] : e.target.value});
    }
    onKeyPress = (e) =>{
        if(e.which == 13)
           this.cargarProducto(this.state.corte.Insumo);
    }
    togglePopOver = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    togglePopOverP = () => {
        this.setState({popoverOpenP: !this.state.popoverOpenP});
    }    
    codigoProd = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarProducto(item.I);
    }
    codigoProdP = (item) =>{
        this.setState({popoverOpenP:false});
        this.cargarProductoP(item.I);
    }
    cargarProducto = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const producto = await resp.json();
        if(producto.Codigo === '' || producto.Depto > 1){
            Swal.fire('Atencion','Este codigo no corresponde a un insumo','error');
            this.setState({cargando:false});
            return;
        }
        const esc = {
            ...this.state.corte,
            NombreIns:producto.Nombre,
            Material:producto.Linea,
            Insumo:producto.Codigo
        };
        this.setState({corte:esc,producto});
    }
    cargarProductoP = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const producto = await resp.json();
        if(producto.Codigo === '' || producto.Depto !== 2){
            Swal.fire('Atencion','Este codigo no corresponde a un subproductos','error');
            this.setState({cargando:false});
            return;
        }
        const { value: ipAddress } = await Swal.fire({
            title: 'Cantidad de ' + producto.Nombre,
            input: 'text',
            inputValue: '0',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Especifica la cantidad'
              }
              let cant = Number(value);
              if(cant > 0){
                  let prod = {
                      Codigo:producto.Codigo,
                      Nombre:producto.Nombre,
                      Cantidad:cant
                  };
                  let {Produce} = this.state.corte;
                  Produce.push(prod);
                  const esc = {
                    ...this.state.corte,
                    Produce
                };
                this.setState({corte:esc});
            }
            }
          });          
        //this.setState({corte:esc,producto});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.corte);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.corte === undefined)
            return null;
        if(Object.keys(this.props.corte).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <div className="row">
                    <div className="col-7">
                    <div className="row">
                        <div className="col-3">
                        <span>Material</span><br/>
                        <InputGroup>
                                <Input size="sm" type="text" name="insumo" value={this.state.corte.Insumo} onChange={this.onChange} onKeyPress={this.onKeyPress} />
                                <InputGroupAddon addonType="append">
                                    <Button size="sm" color="success" id="btnBuscarIns">
                                        <img src="/imagenes/zoom.png" alt="buscar" title="buscar"/>
                                    </Button>
                                    <Popover placement="right" isOpen={this.state.popoverOpen} target="btnBuscarIns" toggle={this.togglePopOver} >
                                        <PopoverBody>
                                            <Buscador onSelect={this.codigoProd} url= {this.props.urlws + "/articulo/matprima?filtro="} />
                                        </PopoverBody>
                                    </Popover>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                        <div className="col-9">
                        <FormGroup>
                            <span>Nombre material</span><br/>
                            <Input size="sm" type="text" name="NombreIns" value={this.state.corte.NombreIns} readOnly />
                        </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                    <div className="col-12">
                        <FormGroup>
                            <span>Nombre</span><br/>
                            <Input size="sm" type="text" name="Nombre" value={this.state.corte.Nombre} onChange={this.onChange} />
                        </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-2">
                            <FormGroup  >
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="Material" id="Material" value={this.state.corte.Material} readOnly  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.lineas.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <span>Calibre</span><br/>
                                <Input size="sm" type="text" name="Calibre" value={this.state.corte.Calibre} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-1">
                            <FormGroup  >
                                <span>Duracion</span><br/>
                                <Input size="sm" type="text" name="Duracion" value={this.state.corte.Duracion} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup  >
                                <span>Unidad</span><br/>
                                <Input size="sm" type="select" name="UnidadTiempo" id="UnidadTiempo" value={this.state.corte.UnidadTiempo} onChange={this.onChange}  >
                                    <option key={-1} value="">Especifique</option>    
                                    {this.props.unidades.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-3">
                            <span>Cantidad</span><br/>
                            <InputGroup>
                                <Input size="sm" type="text" name="Cantidad" value={this.state.corte.Cantidad} onChange={this.onChange} />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText size="sm">Cos.</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-12">
                        <FormGroup  >
                            <span>Instrucciones</span><br/>
                            <textarea class="form-control" rows="5" name="Instrucciones" value={this.state.corte.Instrucciones} onChange={this.onChange}></textarea>
                        </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Button color="primary" onClick={this.guardar}>Aceptar</Button>
                            {'  '}
                            <Button color="danger" onClick={this.props.onClose}>Cancelar</Button>
                        </div>
                    </div>
                    </div>
                    <div className="col-5">
                        <div>
                            <label>Articulos que se producen</label>
                            <span>&nbsp;</span><br/>
                            <InputGroup>
                                <Input size="sm" type="text" name="produce" value={this.state.produce} onChange={this.onChangeB} onKeyPress={this.onKeyPressProduce} />
                                <InputGroupAddon addonType="append">
                                    <Button size="sm" color="success" id="btnBuscarInsP">
                                        <img src="/imagenes/zoom.png" alt="buscar" title="buscar"/>
                                    </Button>
                                    <Popover placement="right" isOpen={this.state.popoverOpenP} target="btnBuscarInsP" toggle={this.togglePopOverP} >
                                        <PopoverBody>
                                            <Buscador onSelect={this.codigoProdP} url= {this.props.urlws + "/articulo/subproductos?filtro="} />
                                        </PopoverBody>
                                    </Popover>
                                </InputGroupAddon>
                            </InputGroup>
                            <Productos productos={this.state.corte.Produce} urlws={this.props.urlws} />
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

class Productos extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            productos:this.props.productos
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            productos:newProps.productos
        });
    }
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    onQuitar = (cve) => {
        console.log(cve);
    }
    onEditar = async(i,cve) => {
        console.log(cve);
        const { value: cant } = await Swal.fire({
            input: 'text',
            title: 'Cambiar cantidad',
            inputValue:cve.Cantidad,
            inputPlaceholder: 'ingrese la cantidad'
        });
        if (cant) {
            this.state.productos[i].Cantidad = parseInt(cant);
            this.setState({corredigo:true});
        }
    }
    render() {
        let { productos } = this.state;
        return (
            <div style={{ height: this.props.height, overflow: 'auto' }}>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ width: 70 }}>Codigo</th>
                            <th>Nombre</th>
                            <th style={{ width: 50 }}>Cant</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {productos.map((esc, i) => (
                            <tr key={i} onRowClick={this.onRowClick}>
                                <td>
                                    <img src={this.props.urlws + esc.Foto} />
                                </td>
                                <td>{esc.Codigo}</td>
                                <td>{esc.Nombre}</td>
                                <td className="numero">{esc.Cantidad}</td>
                                <td>
                                    <img src="/imagenes/pencil.png" alt="editar" title="editar" onClick={() => this.onEditar(i,esc)}/>
                                </td>
                                <td>
                                    <img src="/imagenes/trash.png" alt="eliminar" title="eliminar" onClick={() => this.onQuitar(esc)}/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}