import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';

export default class Kardex extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            popoverOpen:false,
            Temporada:this.props.temp,
            codigo:'',
            articulo:{},
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            almacenes:[],
            movs:[],
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        this.setState({cargando:false, almacenes });
    }
    cargarMovs = async () => {
        const {almacen} = this.state;
        const {fechaIni} = this.state;
        const {fechaFin} = this.state;
        const {codigo} = this.state;
        this.setState({cargando:true});
        console.log(this.state);
        let url = `${this.props.urlws}/articulo/kardex?alm=${almacen}&fecIni=${fechaIni}&fecFin=${fechaFin}&cod=${codigo}`;
        let resp = await fetch(url);
        const movs = await resp.json();
        this.setState({cargando:false, movs });
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarArticulo(this.state.codigo);
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarArticulo(item.I);
    }
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo === ''){
            Swal.fire('Atencion','Codigo no registrado','error');
            return;
        }
        this.setState({
            codigo:articulo.Codigo,
            cargando:false,
            articulo
        });
    }
    render(){
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br />
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br />
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Codigo &nbsp;</span>
                                <InputGroup>
                                    <Input size="sm" type="text" name="codigo" value={this.state.codigo} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                    <InputGroupAddon addonType="append">
                                        <Button size="sm" color="success" id="btnBuscar">
                                            <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                        </Button>
                                        <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                            <PopoverBody>
                                                <Buscador tema='arti' onSelect={this.codigoIns} url={this.props.urlws + "/articulo/articulos?depto=0&filtro="} />
                                            </PopoverBody>
                                        </Popover>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </div>
                        <div className="col">
                            <span>&nbsp;</span><br />
                            <InputGroup>
                                <Input size="sm" type="text" value={this.state.articulo.Nombre} readOnly />
                                <InputGroupAddon addonType="append">
                                <Button color='success' size="sm" onClick={this.cargarMovs}>
                                    <img src="/imagenes/check.png" alt="cargar" title="cargar" />
                                </Button>
                                    
                                    </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.movs} height={500}/>
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        //this.props.onRowClick(cve);
    }
    render() {
        let { movs } = this.props;
        let ent = 0;
        let sal = 0;
        let ini = 0;
        let act = 0;
        let entV = 0;
        let salV = 0;
        let iniV = 0;
        let actV = 0;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th colSpan="3" style={{textAlign:"center"}}>Unidades</th>
                        <th colSpan="3" style={{textAlign:"center"}}>Costo</th>
                    </tr>
                    <tr>
                        <th style={{width:100}}>Referencia</th>
                        <th style={{width:80}}>Fecha</th>
                        <th>Concepto</th>
                        <th className="numero" style={{width:80}}>Entrada</th>
                        <th className="numero" style={{width:80}}>Salida</th>
                        <th className="numero" style={{width:80}}>Saldo</th>
                        <th className="numero" style={{width:80}}>Entrada</th>
                        <th className="numero" style={{width:80}}>Salida</th>
                        <th className="numero" style={{width:80}}>Saldo</th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => {
                     if(esc.T === "E"){
                        ent += esc.eU;
                        entV += esc.eV;
                     }
                     else if(esc.T === "S"){
                        sal += esc.sU;
                        salV += esc.sV;
                     }
                     else{
                        ini = esc.aU;
                        iniV = esc.aV;
                     }
                     act = esc.aU;
                     actV = esc.aV;
                    return <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} />
                    })}
                    <tr>
                        <td></td>
                        <td>TOTALES</td>
                        <th className="numero" >Saldo Anterior : {ini.formato()}</th>
                        <th className="numero" style={{width:80}}>{ent.formato()}</th>
                        <th className="numero" style={{width:80}}>{sal.formato()}</th>
                        <th className="numero" style={{width:80}}>{act.formato()}</th>
                        <th className="numero" style={{width:80}}>{entV.formato()}</th>
                        <th className="numero" style={{width:80}}>{salV.formato()}</th>
                        <th className="numero" style={{width:80}}>{actV.formato()}</th>
                    </tr>
                </tbody>
                </Table>
            </div>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        //this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        //this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Ref}</td>
            <td>{mov.Fec}</td>
            <td>{mov.Con}</td>
            <th className="numero" style={{width:80}}>
                {mov.T === "E" ? mov.eU.formato() : ""}
            </th>
            <th className="numero" style={{width:80}}>
                {mov.T === "S" ? mov.sU.formato() : ""}
            </th>
            <th className="numero" style={{width:80}}>{mov.aU.formato()}</th>
            <th className="numero" style={{width:80}}>
                {mov.T === "E" ? mov.eV.formato() : ""}
            </th>
            <th className="numero" style={{width:80}}>
                {mov.T === "S" ? mov.sV.formato() : ""}
            </th>
            <th className="numero" style={{width:80}}>{mov.aV.formato()}</th>
        </tr>
        );
    }
}
