import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Trabajos extends React.Component {
    objInicial = {Numero:0,Codigo:'',Nombre:'',Tipo:0};
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'?',
            articulo:{},
            articulos: [],
            lineas:[]
        };
        this.cargarCatalogos();
        this.cargarArticulos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarArticulos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/trabajo/trabajos?filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const articulos = await resp.json();
        this.setState({cargando:false, articulos });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/trabajo/tipos`;
        let resp = await fetch(url);
        const lineas = await resp.json();
        this.setState({cargando:false, lineas});
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarArticulos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarArticulos();
    }    
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/trabajo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo == ''){
            articulo.Numero = 0;
            articulo.Codigo = cve;
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            articulo
        });
    }
    guardarArticulo = async(articulo) =>{
        const url = this.props.urlws +"/trabajo/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(articulo);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoArticulo = () => {
        this.cargarArticulo("NUEVO");
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Trabajos</NavbarBrand >
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" color="success" onClick={this.nuevoArticulo}>+</Button>
                            &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                            &nbsp;
                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ArticuloTable articulos={this.state.articulos} height={500} width={990} onRowClick={this.cargarArticulo} />
                </div>
                <ArticuloForm urlws={this.props.urlws} visible={this.state.formVisible} lineas={this.state.lineas} unidades={this.state.unidades} articulo={this.state.articulo} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarArticulo}/>
            </Container>
        );
    }
}

class ArticuloTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { articulos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Codigo</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {articulos.map((esc, i) => (
                        <ArticuloRow key={i} articulo={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ArticuloRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.articulo.I);
    }
    render(){
        let {articulo} = this.props;
        return (
        <tr>
            <td>{articulo.I}</td>
            <td>{articulo.N}</td>
            <td>
                <Button color="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class ArticuloForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //articulo:this.props.articulo,
            articulo:this.props.articulo,
            ...this.props.articulo,
            lin:0
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            articulo:newProps.articulo,
            ...newProps.articulo
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const esc = {
            ...this.state.articulo,
            [campo] : e.target.value
        };
        this.setState({articulo:esc},() =>{
        });
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.articulo);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.articulo === undefined)
            return null;
        if(Object.keys(this.props.articulo).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <div className="col-3">
                        <FormGroup >
                            <span>Codigo</span><br/>
                            <Input size="sm" type="text" name="Codigo" value={this.state.articulo.Codigo} />
                        </FormGroup>
                        </div>
                    </div>                    
                    <div class="row">
                        <div className="col-12">
                        <FormGroup  >
                            <label>Nombre</label>
                            <Input size="sm" type="text" name="Nombre" value={this.state.articulo.Nombre} onChange={this.onChange} />
                        </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col-4">
                            <FormGroup  >
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="Tipo" id="Tipo" value={this.state.articulo.Tipo} onChange={this.onChange}  >
                                    <option key={-1} value={-1}>Especifique</option>    
                                    {this.props.lineas.map((i,k) =>(
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                    <div class="row">
                        <div className="col">
                            <FormGroup  >
                                <span>Descripcion</span><br/>
                                <textarea class="form-control" rows="5" name="Descripcion" value={this.state.articulo.Descripcion} onChange={this.onChange} ></textarea>
                            </FormGroup>
                        </div>                        
                    </div>
                </Form>
                <br />
                <Button size="sm" color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button size="sm" color="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}