import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class CentrosTrabajo extends React.Component {
    objInicial = {Id:'',Nombre:'',Costo:0};
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'',
            objeto:{},
            objetos: []
        };
        this.cargarObjetos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarObjetos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/prod/centros?filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const objetos = await resp.json();
        this.setState({cargando:false, objetos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarObjetos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarObjetos();
    }    
    cargar = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/prod/cargarCentro/" + cve;
        const resp = await fetch(url);
        const objeto = await resp.json();
        if(objeto.Id == 0){
            objeto.Id = cve;
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            objeto
        });
    }
    guardar = async(obj) =>{
        const url = this.props.urlws +"/prod/wsguardarCentro";
        this.setState({cargando:true});
        const json = JSON.stringify(obj);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevo = () => {
        this.cargar(0);
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Centros de trabajo</NavbarBrand >
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" color="success" onClick={this.nuevo}>+</Button>
                            &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                            &nbsp;
                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ObjetoTable objetos={this.state.objetos} height={500} width={990} onRowClick={this.cargar} />
                </div>
                <ObjetoForm urlws={this.props.urlws} visible={this.state.formVisible} objeto={this.state.objeto} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardar}/>
            </Container>
        );
    }
}

class ObjetoTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { objetos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Id</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {objetos.map((esc, i) => (
                        <ObjetoRow key={i} objeto={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ObjetoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.objeto.I);
    }
    render(){
        let {objeto} = this.props;
        return (
        <tr>
            <td>{objeto.I}</td>
            <td>{objeto.N}</td>
            <td>
                <Button color="primary" size="sm" onClick={this.onClick}>
                    <img src="/imagenes/pencil.png" alt="editar" title="editar"/>
                </Button>
            </td>
        </tr>
        );
    }
}

class ObjetoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //articulo:this.props.articulo,
            objeto:this.props.objeto,
            ...this.props.objeto
        };
    }
    componentWillReceiveProps(newProps){
        this.setState({
            objeto:newProps.objeto,
            ...newProps.objeto
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const esc = {
            ...this.state.objeto,
            [campo] : e.target.value
        };
        this.setState({objeto:esc});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.objeto);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.objeto === undefined)
            return null;
        if(Object.keys(this.props.objeto).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <Form>
                    <div class="row">
                        <FormGroup md="4" >
                            <label>Id</label>
                            <Input size="sm" type="text" name="Id" value={this.state.objeto.Id} />
                        </FormGroup>
                    </div>
                    <div class="row">
                        <FormGroup  >
                            <label>Nombre</label>
                            <Input size="sm" type="text" name="Nombre" value={this.state.objeto.Nombre} onChange={this.onChange} style={{ width: 700 }} />
                        </FormGroup>
                    </div>
                    <div class="row">
                        <FormGroup  >
                            <label>Costo</label>
                            <Input size="sm" type="text" name="Costo" value={this.state.objeto.Costo} onChange={this.onChange} style={{ width: 700 }} />
                        </FormGroup>
                    </div>
                </Form>
                <br />
                <Button color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button color="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}