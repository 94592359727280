import React from 'react';
import { Nav,Navbar,NavbarBrand, Collapse, Table, Form, FormGroup, Col, Input, Button, Container, Spinner } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class SubProductos extends React.Component {
    objInicial = {Id:0,Codigo:'',Nombre:'',Depto:2,Linea:1,Sublinea:1,Descripcion:''};
    constructor(props) {
        super(props);
        this.state = {
            esNuevo:false,
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'S',
            articulo:{},
            articulos: []
        };
        this.cargarArticulos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarArticulos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/articulo/articulos?depto=2&filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const articulos = await resp.json();
        this.setState({cargando:false, articulos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarArticulos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarArticulos();
    }    
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        let esNuevo = false;
        if(articulo.Depto > 0 && articulo.Depto !== 2){
            Swal.fire('Atencion',articulo.Nombre + " no un subproducto",'error');
            this.setState({cargando:false});
            return;
        }
        if(articulo.Codigo == ''){
            esNuevo = true;
            articulo.Id = 0;
            articulo.Codigo = cve;
            articulo.Depto = 2;
            articulo.Linea = 1;
            articulo.SubLinea = 1;
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            articulo,
            esNuevo
        });
    }
    guardarArticulo = async(articulo) =>{
        const url = this.props.urlws +"/articulo/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(articulo);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoArticulo = () => {
        this.cargarArticulo("NUEVO");
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>SubProductos</NavbarBrand >
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" color="success" onClick={this.nuevoArticulo}>+</Button>
                            &nbsp;
                            <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                            &nbsp;
                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ArticuloTable articulos={this.state.articulos} height={500} width={990} onRowClick={this.cargarArticulo} />
                </div>
                <ArticuloForm visible={this.state.formVisible} articulo={this.state.articulo} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarArticulo} urlws={this.props.urlws} esNuevo={this.state.esNuevo}/>
            </Container>
        );
    }
}

class ArticuloTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { articulos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Codigo</th>
                        <th>Nombre</th>
                        <th className="imgBtn"></th>
                    </tr>
                </thead>
                <tbody>
                    {articulos.map((esc, i) => (
                        <ArticuloRow key={i} articulo={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ArticuloRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.articulo.I);
    }
    render(){
        let {articulo} = this.props;
        return (
        <tr>
            <td>{articulo.I}</td>
            <td>{articulo.N}</td>
            <td className="imgBtn">
                <img src="/imagenes/pencil.png" alt="editar" title="editar"  onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}

class ArticuloForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            articulo:this.props.articulo,
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            articulo:newProps.articulo
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.articulo,
            [e.target.name] : e.target.value
        };
        this.setState({articulo:esc});
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.articulo);
            }
          })        
    }
    seleccionarFoto = async() =>{
        const { value: file } = await Swal.fire({
            title: 'Select image',
            input: 'file',
            inputAttributes: {
              accept: 'image/*',
              'aria-label': 'subir foto'
            }
          });
          
          if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
             const MySwal = withReactContent(Swal);
              MySwal.fire({
                text: "¿ Desea guardar esta foto ?",
                imageUrl: e.target.result,
                imageAlt: 'Foto seleccionada',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
              }).then((result) => {
                if(result.value){
                    this.subirArchivo(file);
                }
              })        
            }
            reader.readAsDataURL(file);
          }        
    }
    subirArchivo = async(file) =>{
        const nom = this.props.esNuevo ? "nuevo":this.state.articulo.Codigo;
        const data = new FormData();
        data.append('file', file);
        data.append('id', nom);
        const resp = await fetch(this.props.urlws + "/articulo/subirArchivo",{
            method: 'POST', // or 'PUT'
            body: data // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion',"Se guardo el archivo seleccionado",'success');
            let {articulo} = this.state;
            articulo.Foto = "/content/fotos/" + nom + ".png"
            this.setState({articulo});
        }
        else
            Swal.fire('Atencion',res,'error');          
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.articulo === undefined)
            return null;
        if(Object.keys(this.props.articulo).length === 0)
            return null;
        return (
            <Container style={estilo}>
                <div className="row">
                    <div className="col-8">
                        <Form>
                            <div class="row">
                                <FormGroup md="4" >
                                    <label>Codigo</label>
                                    <Input size="sm" type="text" name="Codigo" value={this.state.articulo.Codigo} />
                                </FormGroup>
                            </div>
                            <div class="row">
                                <FormGroup  >
                                    <label>Nombre</label>
                                    <Input size="sm" type="text" name="Nombre" value={this.state.articulo.Nombre} onChange={this.onChange} style={{ width: 700 }} />
                                </FormGroup>
                            </div>
                            <div class="row">
                                <FormGroup  >
                                    <label>Descripcion</label>
                                    <textarea class="form-control" rows="5" name="Descripcion" value={this.state.articulo.Descripcion} onChange={this.onChange} style={{ width: 700 }}></textarea>
                                </FormGroup>
                            </div>
                        </Form>
                    </div>
                    <div className="col-4">
                            <Button color="warning" onClick={this.seleccionarFoto}>Seleccionar</Button>
                            <br/>
                            <br/>
                            <img src={this.props.urlws + this.state.articulo.Foto} />
                    </div>
                </div>
                <br />
                <Button color="primary" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button color="danger" onClick={this.props.onClose}>Cancelar</Button>
            </Container>
        );
    }
}