import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader, Row } from 'reactstrap';
import { Nav, InputGroupText, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody, ModalFooter,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';

export default class Etapas extends React.Component {
    constructor(props) {
        super(props);
        let fec = new Date().yyyymmdd('-');
        this.state = {
            version:1,
            fecha:fec,
            popoverOpen:false,
            cargando:true,
            formVisible: false,
            producto:[],
            codigo:'000075',
            clave: 0,
            nombre:'?',
            corte:{},
            etapas: []
        };
        this.cargarCatalogos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarDetalle = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/proyecto/diario/${this.state.fecha}`;
        const resp = await fetch(url);
        const etapas = await resp.json();
        this.setState({cargando:false, etapas });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/lineas/1`;
        let resp = await fetch(url);
        const lineas = await resp.json();
        url = `${this.props.urlws}/prod/unidadesTiempo`;
        resp = await fetch(url);
        const unidades = await resp.json();
        this.setState({cargando:false, lineas, unidades });
    }
    onKeyPress = (e) =>{
        if(e.which == 13)
           this.cargarProducto(this.state.codigo);
    }
    togglePopOver = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    codigoProd = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarProducto(item.I);
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarCortes();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container >
                {this.state.cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="primary" />
                    </div>
                    :
                    null
                }
                <Row>
                    <FormGroup as={Col} md="4" >
                        <span>Inicio</span>
                        <Input size="sm" type="date" name="fecha" value={this.state.fecha} onChange={this.onChange} />
                    </FormGroup>
                    <FormGroup as={Col} md="1" >
                        <span><br/></span>
                        <span>&nbsp;&nbsp;</span>
                        <Button size="sm" color="success" onClick={this.cargarDetalle}>Aceptar</Button>
                    </FormGroup>
                </Row>

                <div style={{ height: 4 }}></div>
                <EtapasTable etapas={this.state.etapas} height={500} width={990} urlws={this.props.urlws} />
            </Container>
        );
    }
}

class EtapasTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { etapas } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Etapa</th>
                        <th>Nombre</th>
                        <th>Inicio</th>
                        <th>Fin</th>
                        <th>Status</th>
                        <th>%Avance</th>
                    </tr>
                </thead>
                <tbody>
                    {etapas.map((e, i) => (
                        <tr>
                            <td style={{width:100}}>{e.OrdenStr}</td>
                            <td>
                                <b>{e.Nom}</b><br/>
                                <span className="letraChica">{e.Tit}</span><br/>
                                <span className="letraChica">{e.Tipo} - <b>{e.Centro}</b></span><br/>
                                <Row>
                                    <Col>
                                    <b>Consume</b><br/>
                                         <ul>
                                            {e.Consume.map((o,i) => <li><img src={this.props.urlws + "/content/fotos/" + o.Cod + ".png"} width="50" height="50" /> {o.Cant} - {o.Nom}</li>)}
                                         </ul>
                                    </Col>
                                    <Col>
                                    <b>Produce</b><br/>
                                         <ul>
                                         {e.Produce.map((o,i)=><li><img src={this.props.urlws + "/content/fotos/" + o.Cod + ".png"} width="50" height="50" /> {o.Cant} - {o.Nom}</li>)}
                                         </ul>
                                    </Col>
                                </Row>
                            </td>
                            <td style={{width:90}}>{e.Ini}</td>
                            <td></td>
                            <td>{e.Status}</td>
                            <td>{e.Avance}</td>
                        </tr>
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}