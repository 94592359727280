import React from 'react';
import { Row, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';

export default class Etapas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            version:1,
            popoverOpen:false,
            cargando:true,
            formVisible: false,
            verEtapas:false,
            producto:[],
            codigo:'000075',
            clave: 0,
            nombre:'?',
            corte:{},
            articulo:{
                Nom:''
            },
            produccion:[],
            etapas: []
        };
        this.cargarCatalogos();
        this.cargarProduccion();
        //this.cargarDetalle();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarProduccion = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/proyecto/enProceso`;
        const resp = await fetch(url);
        const produccion = await resp.json();
        this.setState({cargando:false, produccion });
    }
    cargarDetalle = async (art) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/proyecto/etapas/${art.Cod}`;
        const resp = await fetch(url);
        const etapas = await resp.json();
        this.setState({cargando:false,verEtapas:true, etapas, articulo:art, codigo:art.Cod });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/lineas/1`;
        let resp = await fetch(url);
        const lineas = await resp.json();
        url = `${this.props.urlws}/prod/unidadesTiempo`;
        resp = await fetch(url);
        const unidades = await resp.json();
        this.setState({cargando:false, lineas, unidades });
    }
    onKeyPress = (e) =>{
        if(e.which == 13)
           this.cargarProducto(this.state.codigo);
    }
    togglePopOver = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    codigoProd = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarProducto(item.I);
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarCortes();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarCortes();
    }    
    render() {
        const estilo1 = { display: this.state.verEtapas ? 'none' : 'inline' };
        const estilo2 = { display: this.state.verEtapas ? 'inline' : 'none' };
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={{ height: 4 }}></div>
                <div style={estilo1}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 150 }}>Codigo</th>
                                <th>Inicia</th>
                                <th>Nombre</th>
                                <th>Proyecto</th>
                                <th>Cliente</th>
                                <th style={{ width: 30 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.produccion.map((e, i) => (
                                <tr>
                                    <td style={{ width: 150 }}>{e.Cod}</td>
                                    <td>{e.Ini}</td>
                                    <td>{e.Nom}</td>
                                    <td>{e.Proy}</td>
                                    <td>{e.Cte}</td>
                                    <td>
                                        <img src="/imagenes/tabla.png" onClick={this.onClick} alt="detalle" title="detalle" onClick={() => this.cargarDetalle(e)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>                
                <div style={estilo2}>
                    <EtapasTable etapas={this.state.etapas} height={500} articulo={this.state.articulo}/>
                    <br/>
                    <Button size="sm" color="danger" onClick={()=>this.setState({verEtapas:false})}>Regresar</Button>
                </div>
            </Container>
        );
    }
}

class EtapasTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { etapas } = this.props;
        return (
            <React.Fragment>
            <Container>
            <Row>
                <Col-2><span>{this.props.articulo.Cod}</span></Col-2>
                <Col><span>{this.props.articulo.Nom}</span></Col>
                <Col><span>{this.props.articulo.Cte}</span></Col>
            </Row>
            </Container>
        <div style={{ height: this.props.height, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Etapa</th>
                        <th>Nombre</th>
                        <th>Inicio</th>
                        <th>Fin</th>
                        <th>Status</th>
                        <th>%Avance</th>
                    </tr>
                </thead>
                <tbody>
                    {etapas.map((e, i) => (
                        <tr>
                            <td style={{width:100}}>{e.OrdenStr}</td>
                            <td>{e.Nombre}</td>
                            <td style={{width:90}}>{e.Inicio}</td>
                            <td></td>
                            <td>{e.Status}</td>
                            <td>{e.Avance}</td>
                        </tr>
                    ))}
                </tbody>
                </Table>
            </div>
            </React.Fragment>
        );
    }
}