import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';
import RequisicionesCotizar from './RequisicionesCotizar';

export default class Requisiciones extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            tipo:'E',
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            clave:'??',
            tipos:[],
            proyectos:[],
            almacenes:[],
            estadosReq:[],
            tipos:[],
            entradas:[],
            usuarios:[],
            cotizaciones:[],
            ordenes:[],
            entrada:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/almacenes`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        url = `${this.props.urlws}/invMovs/tiposReq`;
        resp = await fetch(url);
        const tipos = await resp.json();
        url = `${this.props.urlws}/usuario/usuarios`;
        resp = await fetch(url);
        const usuarios = await resp.json();
        url = `${this.props.urlws}/invMovs/statusReq`;
        resp = await fetch(url);
        const estadosReq = await resp.json();
        url = `${this.props.urlws}/prod/proyectos`;
        resp = await fetch(url);
        const proyectos = await resp.json();
        this.setState({cargando:false, almacenes, usuarios,estadosReq, tipos,proyectos });
    }
    cargarMovs = async () =>{
        if(this.state.almacen == 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        const alm = this.state.almacen;
        const cve = this.state.clave; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/wsrequisiciones?alm=${alm}&cve=${cve}&fecIni=${fecIni}&fecFin=${fecFin}`;
        let resp = await fetch(url);
        const entradas = await resp.json();
        this.setState({cargando:false, entradas });
    }
    enviarOrdenEmail = async(alm,cve,num,email) =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invmovs/enviarOrdenCompra?alm=${alm}&cve=${cve}&num=${num}&email=${email}`;
        const resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok")
            Swal.fire('Atencion','Se envio la orden','success');
        else
            Swal.fire('Atencion',res,'error');
    }

    cargarDetalle = (mov) =>{
        this.cargarMov(mov.Almacen,mov.Clave,mov.Numero);
    }
    cargarMov = async (alm,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/cargarReq?alm=${alm}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        let cotizaciones = [];
        let ordenes = [];
        const entrada = await resp.json();
        if(entrada.Status > 1){
            url = `${this.props.urlws}/invMovs/cargarCotizaReq?alm=${alm}&cve=${cve}&num=${num}`;
            resp = await fetch(url);
            cotizaciones = await resp.json();    
            url = `${this.props.urlws}/invMovs/ordenesReq?alm=${alm}&cve=${cve}&num=${num}`;
            resp = await fetch(url);
            ordenes = await resp.json();    
        }
        this.setState({cargando:false, editando:true, entrada, cotizaciones, ordenes });
    }
    cargarCotizas = async (alm,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/cargarCotizaReq?alm=${alm}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const cotizaciones = await resp.json();
        url = `${this.props.urlws}/invMovs/ordenesReq?alm=${alm}&cve=${cve}&num=${num}`;
        resp = await fetch(url);
        const ordenes = await resp.json();
        this.setState({cargando:false, ordenes,cotizaciones });
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        const cve = this.state.clave;
        if(cve === "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.cargarMov(alm,cve,0);
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    guardar = async (obj) =>{
        const mov = {
            Almacen : this.state.almacen,
            Clave : this.state.clave,
            Numero : obj.Numero,
            Nombre : '',
            Fecha : obj.Fecha,
            Concepto : obj.Concepto,
            Proyecto:obj.Proyecto,
            Solicito:obj.Solicito,
            S: JSON.stringify(obj.Detalle)
        }
        const url = this.props.urlws +"/invMovs/wsguardarReq";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/invmovs/imprimirReq' + params;
        window.open(url, '', '');
    }
    cambiarStatus = async(mov) =>{
        if(mov.Status === 1){
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: 'Confirme',
                text: "¿ Iniciar el proceso de cotizacion para la requisicion " + mov.Numero + "?",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
              }).then(async(result) => {
                if (result.value) {
                    const status = mov.Status + 1;
                    this.setState({cargando:true});
                    const url = `${this.props.urlws}/invMovs/cambiarStatusReq?alm=${mov.Almacen}&cve=${mov.Clave}&num=${mov.Numero}&status=${status}`;
                    const resp = await fetch(url);
                    const txt = await resp.text();
                    this.setState({cargando:false });
                    if(txt === "ok")
                        this.cargarMovs();
                    else
                        Swal.fire('Atencion',txt,'error');
                }
              })            
        }
    }
    crearCotizacion = async(mov,prov,rens) =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invMovs/crearCotizaReq?alm=${mov.Almacen}&cve=${mov.Clave}&num=${mov.Numero}&prov=${prov}&rens=${rens}`;
        const resp = await fetch(url);
        const txt = await resp.text();
        this.setState({cargando:false });
        if(txt === "ok"){
           Swal.fire('Atencion','Cotizacion creada con exito','success');
           this.cargarCotizas(mov.Almacen,mov.Clave,mov.Numero);
        }
        else
           Swal.fire('Atencion',txt,'error');
    }
    guardarPrecio = async(mov,id,pre,obs,sel) =>{
        const s = sel?"S":"N";
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invMovs/grabaCotizaReqPrecio?id=${id}&precio=${pre}&obs=${obs}&sel=${s}`;
        const resp = await fetch(url);
        const txt = await resp.text();
        this.setState({cargando:false });
        if(txt === "ok"){
           this.cargarCotizas(mov.Almacen,mov.Clave,mov.Numero);
        }
        else
           Swal.fire('Atencion',txt,'error');
    }
    crearOrdenes = async(mov,prov) =>{
        this.setState({cargando:true});
        const url = `${this.props.urlws}/invMovs/crearOrdenesCompraReq?alm=${mov.Almacen}&cve=${mov.Clave}&num=${mov.Numero}&prov=${prov}`;
        const resp = await fetch(url);
        const txt = await resp.text();
        this.setState({cargando:false });
        if(!isNaN(txt)){
           Swal.fire('Atencion','Se crearon ' + txt + ' ordenes.','success');
           this.cargarCotizas(mov.Almacen,mov.Clave,mov.Numero);
        }
        else
           Swal.fire('Atencion',txt,'error');
    }

    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(            
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    {
                        this.state.entrada.Status < 2 ?
                        <Captura entrada={this.state.entrada} height={400} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar} usuarios={this.state.usuarios} proyectos={this.state.proyectos}/>
                        :
                        <RequisicionesCotizar entrada={this.state.entrada} height={220} urlws={this.props.urlws} usuarios={this.state.usuarios} cancelar={this.cancelar} crearCotizacion={this.crearCotizacion} cotizaciones={this.state.cotizaciones} ordenes={this.state.ordenes} guardarPrecio={this.guardarPrecio} crearOrdenes={this.crearOrdenes} enviarEmail={this.enviarOrdenEmail}/>
                    }
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-3">
                            <FormGroup>
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange}>
                                    <option key="0" value="??">Especifique</option>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Almacen</span><br/>
                                <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.almacenes.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable entradas={this.state.entradas} height={500} width={990} onRowClick={this.cargarDetalle} imprimir={this.imprimir} cambiarStatus={this.cambiarStatus} />
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { entradas } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Nombre</th>
                        <th className="celdaNumero60">Numero</th>
                        <th>Fecha</th>
                        <th>Proyecto</th>
                        <th>Status</th>
                        <th>Solicito</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {entradas.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    cambiar = (e) =>{
        this.props.cambiarStatus(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Clave}</td>
            <td>{mov.Nombre}</td>
            <td className="numero">{mov.Numero}</td>
            <td>{mov.Fecha}</td>
            <td>{mov.NomProy}</td>
            <td>{mov.NomStatus}</td>
            <td>{mov.Sol}</td>
            <td>
                {
                    mov.Status === 1 ?
                        <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
                    :
                        <img src="/imagenes/cotizar.png" alt="cotizar" title="cotizar" onClick={this.onClick}/>
                }
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
            <td>
                <img src="/imagenes/candado.png" alt="cambiar status" title="cambiar status" onClick={this.cambiar}/>
            </td>
        </tr>
        );
    }
}

class Captura extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agregando:false,
            cambio:false,
            mostrarModal:false,
            cargando:false,
            codigo:'000009',
            nombre:'',
            articulo:{},
            cantidad:1,
            costo:0,
            importe:0,
            renglon:0,
            renSig:1,
            indice:0,
            Detalle:[],
            ...this.props.entrada
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            cambio:false,
            ...newProps.entrada,
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        let valor = e.target.value;
        this.setState({ [campo] : valor, cambio: true});
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarArticulo(this.state.codigo);
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    enter = (campo) =>{
        let foco = campo;
        if(campo === "codigo")
           foco = "cantidad";
        if(campo === "cantidad"){
            const cantidad = Number(this.state.cantidad);
            this.setState({cantidad},()=>{
                this.agregarRenglon();
            });
            foco = "codigo";
        }
         document.getElementById(foco).focus();
    }
    agregarRenglon = () =>{
        if(this.state.renglon === 0){
            const det = {
                Renglon:this.state.renSig,
                Codigo:this.state.codigo,
                Nombre:this.state.nombre,
                Cantidad:this.state.cantidad,
                Costo:Number(this.state.costo),
                Importe:this.state.importe,
            };
            let Detalle = this.state.Detalle;
            Detalle.push(det);
            this.setState({cambio:true, Detalle,renSig:this.state.renSig + 1});
        }
        else{
            let Detalle = this.state.Detalle;
            const indice = this.state.indice;
            Detalle[indice].Codigo = this.state.codigo;
            Detalle[indice].Nombre = this.state.nombre;
            Detalle[indice].Cantidad = this.state.cantidad;
            Detalle[indice].Costo = this.state.costo;
            Detalle[indice].Importe = this.state.importe;
            this.setState({mostrarModal:false, cambio:true});
        }
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarArticulo(item.I);
    }
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo === '' || articulo.Depto === 3){
            Swal.fire('Atencion','Este codigo no corresponde a un insumo o subproducto','error');
            this.setState({cargando:false});
            return;
        }
         this.setState({
            cargando:false,
            codigo:articulo.Codigo,
            nombre:articulo.Nombre,
            costo:articulo.Costo,
            importe:articulo.Costo * this.state.cantidad,
            articulo
        });
        if(this.state.esAgro)
            document.getElementById("dosis").focus();
        else
            document.getElementById("cantidad").focus();
    }
    agregarDetalle = () =>{
        this.setState({mostrarModal:true, renglon : 0, agregando:true});
    }
    quitarRenglon = (ren,i) =>{
        let Detalle = this.state.Detalle;
        Detalle.splice(i, 1);
        this.setState({cambio:true, Detalle});
    }
    editarRenglon = (ren,i) =>{
        this.setState({
            agregando:false,
            mostrarModal:true,
            renglon:ren.Renglon,
            codigo:ren.Codigo,
            nombre:ren.Nombre,
            cantidad:ren.Cantidad,
            costo:ren.Costo,
            importe:ren.Importe,
            indice:i
        });
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        if(this.state.Solicito == 0){
            Swal.fire('Atencion','Especifique a quien solicita la requisicion','error');
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    render(){
        const colS = this.state.esAgro ? "col-6" : "col";
        return(
            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <FormGroup>
                            <span>Numero</span><br />
                            <Input size="sm" type="text" name="Numero" value={this.state.Numero} readOnly />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <span>Fecha</span><br />
                            <Input size="sm" type="date" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <span>Solicito</span><br />
                            <Input size="sm" type="select" name="Solicito" value={this.state.Solicito} onChange={this.onChange}>
                                <option key="0" value={0}>Especifique</option>
                                {this.props.usuarios.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <span>Proyecto</span><br />
                            <Input size="sm" type="select" name="Proyecto" value={this.state.Proyecto} onChange={this.onChange}>
                                <option key="0" value={0}>No definido</option>
                                {this.props.proyectos.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </div>
                </div>
                {this.state.esServ ?
                                <div className="row">
                                <div className="col">
                                    <FormGroup>
                                        <span>Concepto</span><br />
                                        <Input size="sm" type="text" name="Concepto" value={this.state.Concepto} readOnly />
                                    </FormGroup>
                                </div>
                            </div>
                    : null
                }
                <div style={{ height: 4 }}></div>
                <div className="row">
                    <div className={colS}>
                        <div style={{ height: this.props.height, overflow: 'auto' }}>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ width: 100 }}>Codigo</th>
                                        <th>Nombre</th>
                                        <th className="numero">Cantidad</th>
                                        <th style={{ width: 30, textAlign: 'center' }}></th>
                                        <th style={{ width: 30, textAlign: 'center' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.Detalle.map((ren, i) => (
                                        <DetalleRow key={i} indice={i} renglon={ren} onRowClick={this.editarRenglon} onRowQuitar={this.quitarRenglon} esAgro={this.state.esAgro} esRef={this.state.esRef} esServ={this.state.esServ} />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                    <span>&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="success" onClick={this.agregarDetalle}>+</Button>
                </div>

                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1} onOpened={()=>{document.getElementById("codigo").focus();}}>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <FormGroup md="3" >
                                    <label>Codigo &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="codigo" value={this.state.codigo} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscar">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.codigoIns} url={this.props.urlws + "/articulo/articulos?depto=1&filtro="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup md="4">
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nombre} readOnly style={{ width: 600 }} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.esAgro ?
                            <div className="col-2">
                                <FormGroup>
                                    <span>Dosis/Ha</span><br/>
                                    <Input size="sm" type="text" name="dosis" value={this.state.dosis} onChange={this.onChange} onKeyPress={(e) => {if(e.which == 13) this.enter("dosis");}} id="dosis"/>
                                </FormGroup>
                            </div> 
                            : null}
                            <div className="col-2">
                                <FormGroup>
                                    <span>Cantidad</span><br/>
                                    <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange} onKeyPress={(e) => {if(e.which == 13) this.enter("cantidad");}} id="cantidad"/>
                                </FormGroup>
                            </div> 
                            <div className="col">
                                <FormGroup>
                                <span>&nbsp;</span><br/>
                                <Button size="sm" color='success' style={{visibility:this.state.agregando?'visible':'hidden'}} onClick={this.agregarRenglon}>+</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

class DetalleRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    onQuitar = (e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea eliminar este renlon (" + this.props.renglon.Nombre + ") ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.onRowQuitar(this.props.renglon, this.props.indice);
            }
        });
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.Codigo}</td>
            <td>{renglon.Nombre}</td>
            <td className="numero">{renglon.Cantidad.formato()}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.onQuitar}/>
            </td>
        </tr>
        );
    }
}