import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Collapse, Nav, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Proyectos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cargando:true,
            formVisible: false,
            clave: '',
            nombre:'?',
            proyecto:{},
            proyectos: [],
            clientes:[]
        };
        this.cargarClientes();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarClientes = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/cliente/clientes`;
        const resp = await fetch(url);
        const clientes = await resp.json();
        this.setState({cargando:false, clientes });
        this.cargarProyectos();
    }
    cargarProyectos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/proyecto/proyectos?fecIni=20190101&fecFin=20201231`;
        const resp = await fetch(url);
        const proyectos = await resp.json();
        this.setState({cargando:false, proyectos });
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarProyectos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarProyectos();
    }    
    cargarProyecto = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/proyecto/cargar/" + cve;
        const resp = await fetch(url);
        const proyecto = await resp.json();
        if(proyecto.Id === 0){
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: cve,
            proyecto
        });
    }
    guardarProyecto = async(proyecto) =>{
        const url = this.props.urlws +"/proyecto/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(proyecto);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    iniciarProyecto = async(proyecto) =>{
        const url = this.props.urlws +"/proyecto/iniciar/" + proyecto.Id;
        this.setState({cargando:true});
        const resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Proyecto iniciado','success');
            this.onFormClose();
            this.cargarProyectos();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoProyecto = () => {
        this.cargarProyecto(0);
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo}>
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Proyectos</NavbarBrand >
                    <Collapse  isOpen={true} id="basic-navbar-nav">
                        <Nav className="mr-auto" size="sm">
                            <Button size="sm" color="success" onClick={this.nuevoProyecto}>+</Button>
                        <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                            &nbsp;
                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                        </Nav>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <ProyectoTable proyectos={this.state.proyectos} height={500} width={990} onRowClick={this.cargarProyecto} />
                </div>
                <ProyectoForm visible={this.state.formVisible} proyecto={this.state.proyecto} clientes={this.state.clientes} onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarProyecto} urlws={this.props.urlws} iniciarProyecto={this.iniciarProyecto}/>
            </Container>
        );
    }
}

class ProyectoTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { proyectos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:100}}>Id</th>
                        <th>Nombre</th>
                        <th>Cliente</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {proyectos.map((esc, i) => (
                        <ProyectoRow key={i} proyecto={esc} onRowClick={this.onRowClick} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class ProyectoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.proyecto.Id);
    }
    render(){
        let {proyecto} = this.props;
        return (
        <tr>
            <td>{proyecto.Id}</td>
            <td>{proyecto.Nombre}</td>
            <td>{proyecto.NomCliente}</td>
            <td>{proyecto.NomStatus}</td>
            <td>
                <img src="/imagenes/pencil.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
        </tr>
        );
    }
}

class ProyectoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            proyecto:this.props.proyecto,
            detalle:this.props.proyecto.Detalle,
            capturarProducto:false,
            codigoProd:'',
            nombreProd:'',
            cantidad:0
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            proyecto:newProps.proyecto,
            detalle:newProps.proyecto.Detalle
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.proyecto,
            [e.target.name] : e.target.value
        };
        this.setState({proyecto:esc});
    }
    onChange2 = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    handleClose = () =>{
        this.setState({capturarProducto:false});
    }
    nuevoProducto = () =>{
        this.setState({
            capturarProducto:true,
            nombreProd:'',
            codigoProd:'',
            cantidad:0
        });
    }
    productoSel = (ins) =>{
        this.setState({
            codigoProd:ins.I,
            nombreProd:ins.N
        });
    }
    agregarProducto = () =>{
        if(this.state.cantidad <= 0)
           return;
        const det = {
            Pro:this.state.proyecto.Id,
            Ren:0,
            Cod:this.state.codigoProd,
            Nom:this.state.nombreProd,
            Can:this.state.cantidad
        };
        this.guardarProducto(det);
    }
    guardarProducto = async(prod) =>{
        const url = this.props.urlws +"/proyecto/wsguardarDetalle";
        const json = JSON.stringify(prod);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            let det = this.state.detalle;
            if(prod.Ren === 0)
                det.push(prod);
            else{
                //ins[this.state.InsumoIndex].Nombre = eta.Nombre;
                //ins[this.state.InsumoIndex].Duracion = eta.Duracion;
            }
            this.setState({detalle:det,cantidad:0});
        }
        else
            Swal.fire('Atencion',res,'error');        
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.proyecto);
            }
          })        
    }
    iniciarProyecto = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea iniciar este proyecto ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.iniciarProyecto(this.state.proyecto);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.proyecto === undefined)
            return null;
        if(Object.keys(this.props.proyecto).length === 0)
            return null;
        const estiloDet = { display: this.props.proyecto.Id > 0 ? 'inline' : 'none'};
        const estiloBot = { display: this.props.proyecto.Status == 1 ? 'inline' : 'none'};
        return (
            <Container style={estilo}>
                <Form>
                    <div style={{width:900}}>
                        <div className="row">
                            <FormGroup as={Col} md="1" >
                                <label>Id</label>
                                <Input size="sm" type="text" name="Id" value={this.state.proyecto.Id}  style={{width:50}}/>
                            </FormGroup>
                            <span>&nbsp;</span>
                            <FormGroup as={Col} >
                                <label>Nombre</label>
                                <Input size="sm" type="text" name="Nombre" value={this.state.proyecto.Nombre} onChange={this.onChange} style={{width:700}}/>
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup as={Col} md="4" >
                                <label>Inicio</label>
                                <Input size="sm" type="date" name="FechaIni" value={this.state.proyecto.FechaIni} onChange={this.onChange} />
                            </FormGroup>
                            <span>&nbsp;</span>
                            <FormGroup as={Col} md="4">
                                <label>Fin</label>
                                <Input size="sm" type="date" name="FechaFin" value={this.state.proyecto.FechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup as={Col} >
                                <label size="sm">Cliente</label>
                                <Input size="sm" type="select" name="Cliente" value={this.state.proyecto.Cliente} onChange={this.onChange} >
                                    <option key={-1} value={-1}>Especifique</option>
                                    {this.props.clientes.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                    </div>
                </Form>
                <br />
                <Button size="sm" color="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button size="sm" color="danger" onClick={this.props.onClose}>Cancelar</Button>
                {'  '}
                <Button size="sm" style={estiloBot} color="primary" onClick={this.iniciarProyecto}>Iniciar proyecto</Button>
                <br/>
                <div style={estiloDet}>
                <h4>Productos   <Button color="primary" type="button" size="sm" onClick={this.nuevoProducto}>Agregar Producto</Button></h4>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Nombre</th>
                            <th style={{ width: 80 }}>Cantidad</th>
                            <th style={{ width: 80 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.detalle.map((i, k) => (
                            <tr key={i.Ren}>
                                <td>{i.Cod}</td>
                                <td>{i.Nom}</td>
                                <td>{i.Can}</td>
                                <td>
                                    <Button color="primary" size="sm" onClick={() => this.editarEtapa(i, k)}><img src="/imagenes/pencil.png" alt="editar" title="editar" /></Button>
                                    &nbsp;
                                    <Button size="sm" color="danger">-</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                </div>
                <Modal id="dlgB" name="dlgB" isOpen={this.state.capturarProducto} toggle={this.handleClose} size="lg"  >
                    <ModalHeader >
                        <span>Productos</span>
                    </ModalHeader>
                    <ModalBody>
                        <Container >
                            <Productos height={300} width='98%' urlws={this.props.urlws} productoSel={this.productoSel}/>
                            <Form>
                                <div>
                                    <div className="row">
                                        <FormGroup as={Col} >
                                            <label size="sm"><b>Producto</b></label>
                                            <Input size="sm" type="text" value={this.state.nombreProd} style={{ width: 370 }} />
                                        </FormGroup>
                                        <FormGroup as={Col} >
                                            <label size="sm"><b>Cantidad</b></label>
                                            <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange2} style={{ width: 90 }} />
                                        </FormGroup>
                                        <FormGroup as={Col} >
                                            <br/>
                                            <Button color="primary" type="button" onClick={this.agregarProducto} >+</Button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </Form>
                        </Container>
                    </ModalBody>
                </Modal>             
            </Container>
        );
    }
}

class Productos extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            cargando:false,
            articulos:[],
            nombre:''
        };
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarArticulos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarArticulos();
    }    
    cargarArticulos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/articulo/produccion?filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const articulos = await resp.json();
        this.setState({cargando:false, articulos });
    }
    agregar = (ins) =>{
        Swal.fire({
            title: 'Cantidad',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (cod) => {
              //this.cargarArticulo(cod);
            },
            allowOutsideClick: () => !Swal.isLoading()
          });
    }
    render(){
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div>
                <Navbar bg="light" expand="lg">
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                            &nbsp;
                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <div style={{ height: this.props.height, width: this.props.width, overflow: 'auto' }}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th style={{width:45}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.articulos.map((esc, i) => (
                                    <tr key={i} onClick={()=> this.props.productoSel(esc)}>
                                        <td>{esc.N}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Container>
        );
    }
}