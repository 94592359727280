import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader, Row } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody, ModalFooter,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';
import axios from 'axios';
import { isTemplateElement } from '@babel/types';
import CortesProd from './CortesProd';

export default class Formulas extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            Archivo:0,
            selectedFile: null,
            articulo:{
                Codigo:'',
            },
            Version:1,
            fotoVisible:false,
            capturarEtapa:false,
            capturarInsumo:false,
            codigo:'P-COS-00-001',
            nombre:'',
            etapas:[],
            insumos:[],
            produce:[],
            centros:[],
            archivos:[],
            UnidadTiempo:'',
            unidades:[],
            cargando : false,
            NombreEta:'',
            DuracionEta:'',
            codigoIns:'',
            nombreIns:'',
            cantidad:0,
            Centro:0,
            Instrucciones:'',
            etapa:-1,
            nomEta:'',
            activeTab: '1',            
            renglon:0,
            insumoIndex:0,
            codTrab:'',
            tipoTrab:0,
            popoverOpen:false,
            popoverOpenProd:false,
            nestedModal:false,
            insumoFoto :{},
            tiposTrab:[],
            trabajos:[],
            bom:[],
            bomtot:[]
        };
        this.cargarCatalogos();
        this.cargarArticulo(this.state.codigo);
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prod/centros?filtro=`;
        let resp = await fetch(url);
        const centros = await resp.json();
        url = `${this.props.urlws}/prod/unidadesTiempo`;
        resp = await fetch(url);
        const unidades = await resp.json();
        url = `${this.props.urlws}/trabajo/tipos`;
        resp = await fetch(url);
        const tiposTrab = await resp.json();
        this.setState({cargando:false, centros, unidades,tiposTrab });
    }
    cargarTrabajos = async (trab) => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/trabajo/lista/${trab}`;
        let resp = await fetch(url);
        const trabajos = await resp.json();
        this.setState({cargando:false, trabajos });
    }
    cargarArchivos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/archivosEtapa/${this.state.IdEtapa}`;
        let resp = await fetch(url);
        const archivos = await resp.json();
        url = `${this.props.urlws}/articulo/cargarEtapaProduce/${this.state.articulo.Codigo}?etapa=${this.state.EtapaId}`;
        resp = await fetch(url);
        const produce = await resp.json();
        this.setState({cargando:false, archivos,produce });
    }
    verFoto = (i) =>{
        this.setState({fotoVisible:true, insumoFoto : i})
    }
    eliminarProduce = async(o,i) =>{
        this.setState({cargando:true});
        //console.log(i);
        let url = `${this.props.urlws}/articulo/eliminarEtapaProduce/${this.state.articulo.Codigo}?etapa=${o.Eta}&renglon=${o.Ren}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        let {produce} = this.state;
        produce.splice(i, 1);
        this.setState({produce});
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarArticulo(item.I);
    }
    agregarProd = async (item,cant) =>{
        const objeto = {Cod:this.state.articulo.Codigo, Ins:item.I, Eta:this.state.EtapaId, Nom:item.N, Can:cant};
        const url = this.props.urlws +"/articulo/wsguardarEtapaProduce";
        const json = JSON.stringify(objeto);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        let {produce} = this.state;
        produce.push(objeto);
        this.setState({produce});
        //this.cargarArticulo(item.I);
    }
    handleCloseEta = () =>{
        this.setState({capturarEtapa:false});
    }
    handleCloseIns = () =>{
        this.setState({capturarInsumo:false});
    }
    handleCloseFoto = () =>{
        this.setState({fotoVisible:false});
    }
    onKeyPress = (e) =>{
        if(e.which == 13)
           this.cargarArticulo(this.state.codigo);
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        if(campo === "etapaXXX"){
            const cb= document.getElementById("cbEtapa");
            const nomEta = cb.options[cb.selectedIndex].text;
            this.setState({ [e.target.name] : valor, nomEta});
            return;
        }
        this.setState({ [e.target.name] : valor},()=>{
            if(campo === "tipoTrab")
               //this.cargarTrabajos(this.state.tipoTrab);
               for (let i = 0; i < this.state.tiposTrab.length; i++) 
                  if(this.state.tiposTrab[i].I == valor)
                     this.setState({Centro:this.state.tiposTrab[i].V}); 
    
        });
    }
    cargarBom = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/cargarBOM/${this.state.codigo}`;
        let resp = await fetch(url);
        let bom  = await resp.json();
        this.setState({cargando:false, bom });
    }
    cargarBomTot = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/cargarBomTot/${this.state.codigo}`;
        let resp = await fetch(url);
        let bomtot  = await resp.json();
        this.setState({cargando:false, bomtot });
    }

    cambiaTab = (t) =>{
        const activeTab = t;
        if(t === '4')
          this.cargarBom();
        if(t === '5')
          this.cargarBomTot();
        this.setState({activeTab});
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    toggleProd = () => {
        this.setState({popoverOpenProd: !this.state.popoverOpenProd});
    }    
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo === '' || articulo.Depto < 3){
            Swal.fire('Atencion','Este codigo no corresponde a un producto o subproducto','error');
            this.setState({cargando:false});
            return;
        }
         this.setState({
            cargando:false,
            codigo:articulo.Codigo,
            nombre:articulo.Nombre,
            etapas:articulo.Etapas,
            insumos:articulo.Formula,
            articulo
        });
    }
    nuevoInsumoActX = () =>{
        this.setState({
            nestedModal:true
        });
    }

    nuevoInsumo = () =>{
        this.setState({
            capturarInsumo:true,
            nombreIns:'',
            cantidad:0,
            renglon:0
        });
    }
    nuevoInsumoAct = () =>{
        //alert(this.state.etapa);
        this.setState({
            capturarInsumo:true,
            nombreIns:'',
            cantidad:0,
            renglon:0
        });
    }
    editarInsumo = (ins,k) =>{
        this.setState({
            capturarInsumo:true,
            codigoIns:ins.Ins,
            nombreIns:ins.Nom,
            cantidad:ins.Can,
            etapa:ins.Eta,
            renglon:ins.Ren,
            insumoIndex:k
        });       
    }
    quitarInsumo = (ins,k) =>{        
        let {insumos} = this.state;
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          text: "¿ Desea quitar " + insumos[k].Nom + " ?",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        }).then((result) => {
          if(result.value){
            this.quitarInsumoB(k);
          }
        })        
    }
    quitarInsumoB = async(k) =>{
        let {insumos} = this.state;
        let ren = insumos[k].Ren;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/articulo/eliminarInsumo/${this.state.articulo.Codigo}?renglon=${ren}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res !== "ok"){
            Swal.fire('Atencion',res,'error');
            return;
        }
        insumos.splice(k, 1);
        this.setState({insumos});    
    }
    agregarInsumo = () =>{
        if(this.state.cantidad <= 0)
           return;
        const insumo = {
            Cod:this.state.codigo,
            Ren:this.state.renglon,
            Nom:this.state.nombreIns,
            Ins:this.state.codigoIns,
            Can:this.state.cantidad,
            Eta:this.state.etapa,
            NomEta:this.state.NombreEta // .nomEta
        };
        this.guardarInsumo(insumo);
    }
    guardarInsumo = async(insumo) =>{
        const url = this.props.urlws +"/articulo/wsguardarInsumoFormula";
        const json = JSON.stringify(insumo);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            let ins = this.state.insumos;
            if(insumo.Ren === 0)
                ins.push(insumo);
            else{
                ins[this.state.insumoIndex].Nom = insumo.Nom;
                ins[this.state.insumoIndex].Ins = insumo.Ins;
                ins[this.state.insumoIndex].Can = insumo.Can;
                ins[this.state.insumoIndex].Eta = insumo.Eta;
                ins[this.state.insumoIndex].NomEta = insumo.NomEta;
            }
            this.setState({insumos:ins,cantidad:0});
        }
        else
            Swal.fire('Atencion',res,'error');        
    }
    nuevaEtapa = () =>{
        this.setState({
            capturarEtapa:true,
            NombreEta:'',
            DuracionEta:'',
            //Centro:0,
            UnidadTiempo:'',
            Instrucciones:'',
            EtapaId: 0,
            IdEtapa:0,
            archivos:[]
        });
    }
    editarEtapa = (eta,k) =>{
        const tipoTrab = this.state.tipoTrab;
        if(tipoTrab !== eta.TipoTrab)
           this.cargarTrabajos(eta.TipoTrab);
        this.setState({
            capturarEtapa:true,
            NombreEta:eta.Nombre,
            DuracionEta:eta.Duracion,
            EtapaId: eta.Etapa,
            Centro:eta.Centro,
            UnidadTiempo:eta.UnidadTiempo,
            Instrucciones:eta.Instrucciones,
            EtapaOrden : eta.Orden,
            codTrab:eta.CodTrab,
            tipoTrab:eta.TipoTrab,
            IdEtapa:eta.Id,
            etapa:eta.Etapa,
            EtapaIndex:k
        },() =>{this.cargarArchivos()});
        
    }
    guardarEtapa = async() =>{
        //const cb = document.getElementById("codTrab");
        //const nomEta = cb.options[cb.selectedIndex].text;
        if(this.state.tipoTrab === 0){
            Swal.fire('Atencion',"Debe especificar el tipo de trabajo",'error');
            return;
        }
        if(this.state.Centro === 0){
            Swal.fire('Atencion',"Debe especificar el centro de trabajo",'error');
            return;
        }
        const eta = {
            Version:this.state.Version,
            Codigo : this.state.codigo,
            Etapa:this.state.EtapaId,
            Id:this.state.IdEtapa,
            Nombre : this.state.NombreEta,
            Duracion : this.state.DuracionEta,
            Centro : this.state.Centro,
            UnidadTiempo:this.state.UnidadTiempo,
            Instrucciones:this.state.Instrucciones,
            Orden : this.state.EtapaOrden,
            CodTrab: this.state.codTrab,
            TipoTrab:this.state.tipoTrab
        };
        const url = this.props.urlws +"/articulo/wsguardarEtapa";
        const json = JSON.stringify(eta);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            let etas = this.state.etapas;
            if(eta.Etapa === 0)
                etas.push(eta);
            else{
                etas[this.state.EtapaIndex].Nombre = eta.Nombre;
                etas[this.state.EtapaIndex].Instrucciones = eta.Instrucciones;
                etas[this.state.EtapaIndex].Duracion = eta.Duracion;
            }
            this.setState({etapas:etas, capturarEtapa:false});
            this.cargarArticulo(this.state.codigo);
        }
        else
            Swal.fire('Atencion',res,'error');        
    }
    insumoSel = (ins) =>{
        this.setState({
            codigoIns:ins.I,
            nombreIns:ins.N
        });
    }

    seleccionarArchivo = async() =>{
        const { value: file } = await Swal.fire({
            title: 'Select image',
            input: 'file',
            inputAttributes: {
              'aria-label': 'subir archivo'
            }
          });
          
          if (file) {
            const MySwal = withReactContent(Swal);
            MySwal.fire({
              text: "¿ Desea subir este archivo ?",
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'No',
              confirmButtonText: 'Si'
            }).then((result) => {
              if(result.value){
                  this.subirArchivo(file);
              }
            })        
          }        
    }
    subirArchivo = async(file) =>{
        const data = new FormData();
        data.append('file', file);
        data.append('id', this.state.IdEtapa);
        const resp = await fetch(this.props.urlws + "/prod/subirArchivo",{
            method: 'POST', // or 'PUT'
            body: data // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion',"Sesubio el archivo seleccionado",'success');
        }
        else
            Swal.fire('Atencion',res,'error');          
    }
    btnVerClick = () =>{
        if(this.state.Archivo === 0)
            return;
        let url = `${this.props.urlws}/prod/verArchivo/${this.state.Archivo}`;
        window.open(url, '', '');
    }
    toggleNested = () =>{
        this.setState({nestedModal:false});
    }
    render(){
        return(
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <Navbar bg="f5e831" expand="lg">
                    <NavbarBrand  size="sm" style={{color:'black'}}>Formulas</NavbarBrand >
                </Navbar>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <label>Codigo &nbsp;</label>
                            <InputGroup>
                                <Input size="sm" type="text" name="codigo" value={this.state.codigo} onChange={this.onChange} onKeyPress={this.onKeyPress} />
                                <InputGroupAddon addonType="append">
                                    <Button size="sm" color="success" id="btnBuscar">
                                        <img src="/imagenes/zoom.png" alt="buscar" title="buscar"/>
                                    </Button>
                                    <Popover placement="right" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                        <PopoverBody>
                                            <Buscador tema="producto" onSelect={this.codigoIns} url= {this.props.urlws + "/articulo/produccion?filtro="} />
                                        </PopoverBody>
                                    </Popover>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                        <label>Nombre &nbsp;</label>
                            <Input size="sm" type="text" value={this.state.nombre} style={{width:600}}/>
                        </FormGroup>
                    </div>
                </div>
                <br/>
                <Nav tabs>
                    <NavItem>
                        <NavLink onClick={() => { this.cambiaTab('1'); }}>
                            Etapas
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.cambiaTab('2'); }}>
                            Cortes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.cambiaTab('3'); }}>
                            Insumos requeridos
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.cambiaTab('4'); }}>
                            Lista de insumos requeridos
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.cambiaTab('5'); }}>
                            Concentrado de insumos requeridos
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => { this.cambiaTab('6'); }}>
                            Arbol                            
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                    <Container>
                            <div className="row">
                                <div className="col" style={{padding:5}}>
                                    <Button color="primary" type="button" size="sm" onClick={this.nuevaEtapa}>Agregar actividad</Button>
                                    <div style={{height:4}}></div>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th style={{width:40}}></th>
                                                <th>Actividad</th>
                                                <th>Centro de trabajo</th>
                                                <th>Duracion</th>
                                                <th>Unidad</th>
                                                <th>Documentos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.etapas.map((i, k) => (
                                                <tr key={i.Etapa}>
                                                    <td>{i.Etapa}</td>
                                                    <td>{i.Nombre}</td>
                                                    <td>{i.NomCentro}</td>
                                                    <td>{i.Duracion}</td>
                                                    <td>{i.UnidadTiempo}</td>
                                                    <td>{i.Documentos}</td>
                                                    <td className="imgBtn">
                                                        <img src="/imagenes/pencil.png" alt="editar" title="editar" onClick={()=> this.editarEtapa(i,k)}/>
                                                    </td>
                                                    <td className="imgBtn">
                                                        <img src="/imagenes/trash.png" alt="quitar" title="quitar" onClick={()=> {}}/>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Container>
                    </TabPane>
                    <TabPane tabId="2">
                        <CortesProd articulo={this.state.articulo} urlws={this.props.urlws} usuario={this.props.usuario} />
                    </TabPane>
                    <TabPane tabId="3">
                    <Container>
                            <div className="row">
                                <div className="col" style={{padding:5}}>
                                    <Button color="primary" type="button" size="sm" onClick={this.nuevoInsumo}>Agregar Insumo</Button>
                                    <div style={{height:4}}></div>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th style={{ width: 80 }}>Cantidad</th>
                                                <th style={{ width: 210 }}>Actividad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.insumos.map((i, k) => (
                                                <tr key={i.Ren}>
                                                    <td>{i.Nom}</td>
                                                    <td>{i.Can}</td>
                                                    <td>{i.NomEta}</td>
                                                    <td className="imgBtn">
                                                        <img src="/imagenes/pencil.png" alt="editar" title="editar" onClick={()=> this.editarInsumo(i,k)}/>
                                                    </td>
                                                    <td className="imgBtn">
                                                        <img src="/imagenes/trash.png" alt="quitar" title="quitar" onClick={()=> this.quitarInsumo(i,k) }/>
                                                    </td>
                                                    <td className="imgBtn">
                                                        <img src="/imagenes/imagen.png" alt="imagen" title="imagen" onClick={()=> this.verFoto(i) }/>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Container>                        
                    </TabPane>
                    <TabPane tabId="4">
                    <Container>
                            <div className="row">
                                <div className="col" style={{padding:5}}>
                                    <div style={{height:4}}></div>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Producto</th>
                                                <th>Insumo</th>
                                                <th>Tipo</th>
                                                <th style={{ width: 80, textAlign:'right' }}>Cantidad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.bom.map((i, k) => (
                                                <tr key={i.Ren}>
                                                    <td>{i.Prod}</td>
                                                    <td>{i.Nom}</td>
                                                    <td>{i.Tipo}</td>
                                                    <td style={{ width: 80, textAlign:'right' }}>{i.Cant}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Container>                        
                    </TabPane>
                    <TabPane tabId="5">
                    <Container>
                            <div className="row">
                                <div className="col" style={{padding:5}}>
                                    <div style={{height:4}}></div>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Insumo</th>
                                                <th style={{ width: 80, textAlign:'right' }}>Cantidad</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.bomtot.map((i, k) => (
                                                <tr key={i.Ren}>
                                                    <td>{i.Nom}</td>
                                                    <td style={{ width: 80, textAlign:'right' }}>{i.Cant}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Container>                        
                    </TabPane>
                    <TabPane tabId="6">
                        <Arbol articulo={this.state.articulo.Codigo} expandido={true} urlws={this.props.urlws} usuario={this.props.usuario} />
                    </TabPane>
                </TabContent>
                <Modal id="dlgFoto" name="dlgFoto" isOpen={this.state.fotoVisible} toggle={this.handleCloseFoto} >
                    <ModalHeader className="body">
                        <h6>{this.state.insumoFoto.Ins} - {this.state.insumoFoto.Nom}</h6>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-4">
                                <img src={this.props.urlws + "/content/fotos/" + this.state.insumoFoto.Ins + ".png"} width="100" height="100" />
                            </div>
                            <div className="col">
                                <table>
                                    <tr>
                                        <td>Cantidad</td>
                                        <td>&nbsp;&nbsp;</td>
                                        <td><b>{this.state.insumoFoto.Can}</b></td>
                                    </tr>
                                    <tr><td>&nbsp;&nbsp;</td></tr>
                                    <tr>
                                        <td >Etapa</td>
                                        <td>&nbsp;&nbsp;</td>
                                        <td><b>{this.state.insumoFoto.NomEta}</b></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal id="dlg" name="dlg" isOpen={this.state.capturarEtapa} toggle={this.handleCloseEta} size="xl" tabIndex={1}>
                    <ModalHeader className="body">
                        <label>Actividad {this.state.EtapaId}</label>
                    </ModalHeader>
                    <ModalBody className="body">
                        <Container >
                            <Form>
                                <div>
                                    <div class="row">
                                        <div className="col-3">
                                            <span>Tipo</span><br/>
                                            <Input size="sm" type="select" name="tipoTrab" id="tipoTrab" value={this.state.tipoTrab} onChange={this.onChange}  >
                                                <option key={-1} value={0}>Especifique</option>    
                                                {this.state.tiposTrab.map((i,k) =>(
                                                    <option key={i.I} value={i.I}>{i.N}</option>
                                                ))}
                                            </Input>
                                        </div>
                                        <div className="col-9">
                                            <span>Nombre</span><br/>
                                            <Input size="sm" type="text" name="NombreEta" value={this.state.NombreEta} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col-1">
                                            <span>Duracion</span><br/>
                                            <Input size="sm" type="text" name="DuracionEta" value={this.state.DuracionEta} onChange={this.onChange} />
                                        </div>
                                        <div className="col-2">
                                            <span>Unidad</span><br/>
                                            <Input size="sm" type="select" name="UnidadTiempo" id="UnidadTiempo" value={this.state.UnidadTiempo} onChange={this.onChange}  >
                                                <option key={-1} value={-1}>Especifique</option>
                                                    {this.state.unidades.map((i, k) => (
                                                        <option key={i.I} value={i.I}>{i.N}</option>
                                                    ))}
                                            </Input>
                                        </div>
                                        <div className="col-3">
                                            <span>Centro de trabajo</span><br/>
                                            <Input size="sm" type="select" name="Centro" id="Centro" value={this.state.Centro} onChange={this.onChange}  >
                                                    <option key={-1} value={-1}>Especifique</option>
                                                    {this.state.centros.map((i, k) => (
                                                        <option key={i.I} value={i.I}>{i.N}</option>
                                                    ))}
                                            </Input>
                                        </div>
                                        <div className="col-4">
                                            <span>Archivos</span><br/>
                                            <InputGroup>
                                                <Input size="sm" type="select" name="Archivo" id="Archivo" value={this.state.Archivo} onChange={this.onChange}  >
                                                        <option value={0}>Seleccione</option>
                                                        {this.state.archivos.map((i, k) => (
                                                            <option key={i.I} value={i.I}>{i.N}</option>
                                                        ))}
                                                </Input>
                                                <InputGroupAddon addonType="prepend">
                                                    <Button size="sm" color="success" onClick={this.btnVerClick}>
                                                        <img src="/imagenes/imagen.png" alt="ver" title="ver" />
                                                        </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className="col-2">
                                            <span>Subir archivo</span><br/>
                                            <InputGroup>
                                                <Button size="sm" color="warning" onClick={this.seleccionarArchivo}>Seleccionar</Button>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col-12">
                                            <span>Instrucciones</span><br/>
                                            <textarea class="form-control" rows="3" name="Instrucciones" value={this.state.Instrucciones} onChange={this.onChange} ></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="col">
                                                <h5>Insumos <Button id="btnInsEt" name="btnProd" size="sm" color="success" onClick={this.nuevoInsumoAct}>+</Button></h5>
                                            </div>
                                            <div className="col">
                                                <div style={{ height: 200, overflow: 'auto' }}>
                                                <Table striped borderless hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre</th>
                                                            <th style={{ width: 40 }}>Cant.</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.insumos.filter(i => i.Eta === this.state.EtapaId).map((i, k) => (
                                                            <tr key={i.Ren}>
                                                                <td>{i.Nom}</td>
                                                                <td>{i.Can}</td>
                                                                <td className="imgBtn">
                                                                    <img src="/imagenes/imagen.png" alt="imagen" title="imagen" onClick={() => this.verFoto(i)} />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="col">
                                                <h5>Produce <Button id="btnProd" name="btnProd" size="sm" color="success" onClick={() => { }}>+</Button></h5>
                                                <Popover trigger="legacy" placement="right" isOpen={this.state.popoverOpenProd} target="btnProd" toggle={this.toggleProd} >
                                                    <PopoverBody>
                                                        <BuscadorProd onAgregar={this.agregarProd} urlws={this.props.urlws} url={this.props.urlws + "/articulo/produccion?filtro="} />
                                                    </PopoverBody>
                                                </Popover>
                                            </div>
                                            <div className="col">
                                                <div style={{ height: 200, overflow: 'auto' }}>
                                                <Table striped borderless hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Nombre</th>
                                                            <th style={{ width: 40 }}>Cant.</th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.produce.map((i, k) => (
                                                            <tr key={k}>
                                                                <td>{i.Nom}</td>
                                                                <td>{i.Can}</td>
                                                                <td className="imgBtn">
                                                                    <img src="/imagenes/imagen.png" alt="imagen" title="imagen" onClick={() => this.verFoto(i)} />
                                                                </td>
                                                                <td className="imgBtn">
                                                                    <img src="/imagenes/trash.png" alt="eliminar" title="eliminar" onClick={() => this.eliminarProduce(i,k)} />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            <br />
                            <Button size="sm" color="primary" onClick={this.guardarEtapa}>Aceptar</Button>
                            {'  '}
                            <Button size="sm" color="danger" onClick={this.handleCloseEta}>Cancelar</Button>
                            {'  '}
                        </Container>
                    </ModalBody>
                </Modal>             

                <Modal id="dlgB" name="dlgB" isOpen={this.state.capturarInsumo} toggle={this.handleCloseIns} size="lg" tabIndex={1} >
                    <ModalHeader closeButton className="body">
                        <h6>Insumos</h6>
                    </ModalHeader>
                    <ModalBody className="body">
                        <Container >
                            <Insumos height={300} width='98%' urlws={this.props.urlws} insumoSel={this.insumoSel}/>
                            <Form>
                                <div>
                                    <div class="row">
                                        <FormGroup>
                                            <label size="sm"><b>Insumo</b></label>
                                            <Input size="sm" type="text" value={this.state.nombreIns} style={{ width: 370 }} />
                                        </FormGroup>
                                        &nbsp;
                                        <FormGroup>
                                            <label size="sm"><b>Cantidad</b></label>
                                            <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange} style={{ width: 90 }} />
                                        </FormGroup>
                                        &nbsp;
                                        <FormGroup>
                                            <label size="sm"><b>Actividad</b></label>
                                            <Input size="sm" type="select" name="etapa" id="cbEtapa" value={this.state.etapa} onChange={this.onChange} style={{ width: 180 }} >
                                                <option key={-1} value={-1}>Especifique</option>    
                                                    {this.state.etapas.map((i,k) =>(
                                                <option key={i.Etapa} value={i.Etapa}>{i.Etapa}-{i.Nombre}</option>    
                                                    ))}
                                            </Input>
                                        </FormGroup>
                                        &nbsp;
                                        <FormGroup>
                                            <label>&nbsp;</label><br/>
                                            &nbsp;<Button size="sm" color="success" onClick={this.agregarInsumo} >+</Button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </Form>
                        </Container>
                    </ModalBody>
                </Modal>             
            </Container>
        )
    }
}
class Insumos extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            cargando:false,
            articulos:[],
            nombre:''
        };
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarArticulos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarArticulos();
    }    
    cargarArticulos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/articulo/insumos?filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const articulos = await resp.json();
        this.setState({cargando:false, articulos });
    }
    agregar = (ins) =>{
        Swal.fire({
            title: 'Cantidad',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (cod) => {
              //this.cargarArticulo(cod);
            },
            allowOutsideClick: () => !Swal.isLoading()
          });
    }
    render(){
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div>
                <Navbar bg="light" expand="lg">
                    <Collapse isOpen={true}>
                        <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                            &nbsp;
                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <div style={{ height: this.props.height, width: this.props.width, overflow: 'auto' }}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th style={{width:100}}>Imagen</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.articulos.map((esc, i) => (
                                    <tr key={i} onClick={()=> this.props.insumoSel(esc)}>
                                        <td>{esc.N}</td>
                                        <td>
                                            <img height="100" width="100" src={this.props.urlws + "/content/fotos/" + esc.I + ".png"} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Container>
        );
    }
}
class BuscadorProd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prod:{},
            nombre: '',
            nombreSel:'',
            cant:0,
            datos: []
        };
    }
    componentDidMount(){
        const estado = localStorage.getItem("buscador");
        if(estado === null)
            return;
        this.setState(JSON.parse(estado));
    }
    componentWillUnmount(){
        const estado = JSON.stringify(this.state);
        localStorage.setItem("buscador", estado);
    }
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    onKeyPress = e => {
        if (e.which === 13)
            this.cargar();
    }
    onClick = e => {
        this.cargar();
    }
    onRowClick = (prod) => {
        this.setState({nombreSel:prod.N, prod});
        document.getElementById("txtCant").focus();
        //this.props.onSelect(item);
        //document.body.click();
    }
    onMasClick = () => {
        this.props.onAgregar(this.state.prod,this.state.cant);
        this.setState({ cant: 0 });
        document.getElementById("txtBuscadorProdNom").focus();
    }
    cargar = async () => {
        const url = this.props.url + this.state.nombre;
        const res = await fetch(url);
        const datos = await res.json();
        this.setState({ datos });
    }
    render() {
        return (
            <div style={{padding:5}} className="body">
                <div className="row">
                    <div className="col-7">
                        <input type="text" id="txtBuscadorProdNom" className="form-control form-control-sm" placeholder="concepto" name="nombre" value={this.state.nombre} onChange={this.onChange} onKeyPress={this.onKeyPress} />
                    </div>
                    <div className="col"><Button size="sm" onClick={this.onClick}>Buscar</Button></div>
                </div>
                <div style={{height:3}}></div>
                <div style={{ height: 300, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.datos.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => this.onRowClick(item)}>
                                        <td>{item.N}</td>
                                        <td>
                                            <img width="100" height="100" src={this.props.urlws + "/content/fotos/" + item.I + ".png"} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table>
                            <tr>
                                <td colspan="3">
                                    <span>{this.state.nombreSel}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Cantidad</td>
                                <td><Input id="txtCant" size="sm" type="text" name="cant" value={this.state.cant} onChange={this.onChange} style={{width:100}}/></td>
                                <td><Button color="success" size="sm" onClick={this.onMasClick}>+</Button></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
class Arbol extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expandido:false,
            articulo:{
                Etapas:[],
                Formula:[]
            },
            produce:[]
        }
        this.cargarArticulo(props.articulo)
    }
    componentWillReceiveProps(newProps){
        this.state.expandido = newProps.expandido;
        this.cargarArticulo(newProps.articulo);
    }
    cargarArticulo = async (cve) => {
        let url = this.props.urlws + "/articulo/cargar/" + cve;
        let resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Codigo === '' || articulo.Depto < 3){
            return;
        }
        url = `${this.props.urlws}/articulo/cargarEtapaProduce/${cve}?etapa=0`;
        resp = await fetch(url);
        const produce = await resp.json();
        this.setState({articulo,produce});
    }    
    toggle = () =>{
        this.setState({expandido : !this.state.expandido});
    }
    render(){
        return(
            <React.Fragment>
                <Button size="sm" color="primary" onClick={this.toggle} style={{ marginBottom: '1rem' }}>Detalle</Button>                 
                <b>
                    &nbsp;
                   {this.state.articulo.Nombre}
                </b>
                <br/>
                <Collapse isOpen={this.state.expandido}>
                <ol>
                    {this.state.articulo.Etapas.map((o,i)=>(
                        <li key={i}>
                            <span className="etapaArbol">{o.Nombre}</span><br />
                            <Row>
                                <div className="col">
                                    <span class="labelArbol">Requiere :</span><br />
                                    <ul className="arbolReq">
                                        {this.state.articulo.Formula.filter(i => i.Eta === o.Etapa).map((o, i) => (
                                            <li key={i}>
                                                {o.F == 'S' ?
                                              <div>{o.Can} -  <Arbol articulo={o.Ins} expandido={false} urlws={this.props.urlws} usuario={this.props.usuario} /></div>
                                               :
                                                <span>{o.Can} - {o.Nom}</span>
                                                }                                                
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Row>
                            <Row>
                                <div className="col">
                                    <span class="labelArbol">Produce :</span><br />
                                    <ul className="arbolProd">
                                        {this.state.produce.filter(i => i.Eta === o.Etapa).map((o, i) => (
                                            <li key={i}>
                                                {o.Can} - {o.Nom}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Row>
                        </li>
                    ))}
                </ol> 
                </Collapse>
            </React.Fragment>
        );
    }
}