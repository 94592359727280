import React from 'react';
import { Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2'

export default class ReportesInv extends React.Component{
    constructor(props){
        let fecha = new Date().yyyymmdd('-');
        super(props);
        this.state = {
            fechaIni:fecha,
            fechaFin:fecha,
            visible:true,
            reporte:-1,
            almacen:0,
            almacenes:[],
            reportes:["Existencia actual","Listado de Entradas","Listado de Salidas"]
        };
        this.cargarCatalogos();
    }
    componentWillReceiveProps(newProps){
        this.setState({
            visible:true
        });
    }

    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/almacen/lista`;
        let resp = await fetch(url);
        const almacenes = await resp.json();
        this.setState({ almacenes });
    }
    imprimir = () =>{
        const rep = this.state.reporte;
        const alm = this.state.almacen;
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        if(rep === -1){
            Swal.fire('Atencion','Especifique el reporte','error');
            return;
        }
        let url = this.props.urlws;
        if(rep == 0){
            let url = `${this.props.urlws}/reportesInv/existenciaActual?alm=${alm}&deptoIni=1&deptoFin=1&lineaIni=1&lineaFin=20&subLinea=0`;
            window.open(url, '', '');
        }
    }
    handleClose = () =>{
        this.setState({visible:false});
    }

    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    render(){
        return (
            <Modal isOpen={this.state.visible} toggle={this.handleClose}>
                <ModalHeader closeButton>
                    <span>Reportes de inventario</span>
                </ModalHeader>
                <ModalBody>
                    <container>
                        <div className="row" style={{padding:10}}>
                            <label size="sm"><b>Reporte</b></label>
                            <Input size="sm" type="select" name="reporte" value={this.state.reporte} onChange={this.onChange}>
                                <option key="" value={-1}>Especifique</option>
                                {this.state.reportes.map((i, k) =>
                                    <option key={k} value={k}>{i}</option>
                                )}
                            </Input>
                        </div>
                        <div className="row" style={{padding:10}}>
                            <label size="sm">Almacen</label>
                            <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.state.almacenes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </div>
                        <div className="row" style={{padding:10}}>
                            <div className="col">
                                <label>Del dia</label>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </div>
                            <div className="col">
                                <label>Al dia</label>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="row" style={{padding:10}}>
                            <Button color="success" onClick={this.imprimir}>Imprimir</Button>
                        </div>
                    </container>
                </ModalBody>
            </Modal>
        )
    }
}
