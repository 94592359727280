import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Buscador from '../Buscador';
import { ENETRESET } from 'constants';

export default class Entradas extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            tipo:'E',
            almacen:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            clave:'??',
            almacenes:[],
            tipos:[],
            entradas:[],
            entrada:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invTipo/lista/E`;
        let resp = await fetch(url);
        const tipos = await resp.json();
        url = `${this.props.urlws}/almacen/lista`;
        resp = await fetch(url);
        const almacenes = await resp.json();
        this.setState({cargando:false, tipos, almacenes });
    }
    cargarMovs = async () =>{
        const alm = this.state.almacen;
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        const cve = this.state.clave;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/wslista?alm=${alm}&fecIni=${fecIni}&fecFin=${fecFin}&cve=${cve}&tipo=E`;
        let resp = await fetch(url);
        const entradas = await resp.json();
        this.setState({cargando:false, entradas });
    }
    cargarDetalle = (mov) =>{
        this.cargarMov(mov.Almacen,mov.Clave,mov.Numero);
    }
    cargarMov = async (alm,cve,num) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/invMovs/cargarEntrada?alm=${alm}&cve=${cve}&num=${num}`;
        let resp = await fetch(url);
        const entrada = await resp.json();
        this.setState({cargando:false, editando:true, entrada });
    }
    nuevoMov = () =>{
        const alm = this.state.almacen;
        const cve = this.state.clave;
        if(cve === "??"){
            Swal.fire('Atencion','Especifique el tipo de movimiento','error');
            return;
        }
        if(alm === 0){
            Swal.fire('Atencion','Especifique el almacen','error');
            return;
        }
        this.cargarMov(alm,cve,0);
    }
    cancelar = () =>{
        this.setState({editando:false});
    }
    guardar = async (obj) =>{
        const mov = {
            Almacen : this.state.almacen,
            Clave : this.state.clave,
            Numero : obj.Numero,
            Nombre : '',
            Fecha : obj.Fecha,
            Concepto : obj.Concepto,
            S: JSON.stringify(obj.Detalle)    
        }
        const url = this.props.urlws +"/invMovs/wsguardar";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false});
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    imprimir = (mov) =>{
        let params = '?alm=' + mov.Almacen + '&cve=' + mov.Clave + '&num=' + mov.Numero;
        let url = this.props.urlws + '/invmovs/imprimir' + params;
        window.open(url, '', '');
    }
    render(){
        const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(    
           <Container>
            <div style={{width:1000}}>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo1}>
                    <Captura entrada={this.state.entrada} height={450} urlws={this.props.urlws} cancelar={this.cancelar} guardar={this.guardar}/>
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <FormGroup as={Col} md="3">
                            <span>Almacen</span><br/>
                            <Input size="sm" type="select" name="almacen" value={this.state.almacen} onChange={this.onChange}>
                                <option key="0" value="">Especifique</option>
                                {this.state.almacenes.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                        <span>&nbsp;</span>
                        <FormGroup as={Col} md="3">
                        <span>Tipo</span><br/>
                            <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange}>
                                <option key="0" value="??">Todas</option>
                                {this.state.tipos.map((i, k) =>
                                    <option key={i.I} value={i.I}>{i.N}</option>
                                )}
                            </Input>
                        </FormGroup>
                        <span>&nbsp;</span>
                        <FormGroup as={Col} md="2">
                        <span>Del dia</span><br/>
                            <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                        </FormGroup>
                        <span>&nbsp;</span>
                        <FormGroup as={Col} md="2">
                        <span>al dia</span><br/>
                            <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                        </FormGroup>
                        <span>&nbsp;</span>
                        <FormGroup as={Col}>
                        <span></span><br/>
                            <Button size="sm" color='success' onClick={this.cargarMovs}>Cargar</Button>
                            {'  '}
                            <Button size="sm" color='success' onClick={this.nuevoMov}>+</Button>
                        </FormGroup>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable entradas={this.state.entradas} height={500} width={990} onRowClick={this.cargarDetalle} imprimir={this.imprimir} />
                </div>                
            </div>
            </Container>         
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { entradas } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Nombre</th>
                        <th>Numero</th>
                        <th>Fecha</th>
                        <th>Concepto</th>
                        <th style={{width:30}} ></th>
                        <th style={{width:30}}></th>
                    </tr>
                </thead>
                <tbody>
                    {entradas.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir} />
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class MovRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Clave}</td>
            <td>{mov.Nombre}</td>
            <td>{mov.Numero}</td>
            <td>{mov.Fecha}</td>
            <td>{mov.Concepto}</td>
            <td>
                <img src="/imagenes/pencil.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
            <td>
                <img src="/imagenes/printer.png" onClick={this.imprimir} alt="imprimir" title="imprimir"/>
            </td>
        </tr>
        );
    }
}

class Captura extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            cambio:false,
            mostrarModal:false,
            cargando:false,
            codigo:'',
            nombre:'',
            articulo:{},
            cantidad:1,
            costo:0,
            importe:0,
            lote:'',
            renglon:0,
            renSig:1,
            indice:0,
            Detalle:[],
            ...this.props.entrada           
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            cambio:false,
            ...newProps.entrada,
        });
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value, cambio: true});
    }
    onKeyPressCodigo = (e) =>{
        if(e.which == 13)
           this.cargarArticulo(this.state.codigo);
    }
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    enter = (campo) =>{
        let foco = campo;
        if(campo === "lote")
           foco = "cantidad";
        if(campo === "cantidad"){
            const cantidad = Number(this.state.cantidad);
            const costo = Number(this.state.costo);
            const importe = Number(cantidad * costo);
            this.setState({cantidad, costo, importe});
            foco = "costo";
        }
        if(campo === "costo"){
            const cantidad = Number(this.state.cantidad);
            const costo = Number(this.state.costo);
            const importe = Number(cantidad * costo);
            this.setState({cantidad, costo, importe});
            foco = "codigo";
            this.agregarRenglon();
         }
         document.getElementById(foco).focus();
    }
    agregarRenglon = () =>{
        if(this.state.renglon === 0){
            const det = {
                Renglon:this.state.renSig,
                Codigo:this.state.codigo,
                Nombre:this.state.nombre,
                Cantidad:this.state.cantidad,
                Costo:this.state.costo,
                Importe:this.state.importe,
                Lote:this.state.lote
            };
            let Detalle = this.state.Detalle;
            Detalle.push(det);
            this.setState({cambio:true, Detalle,renSig:this.state.renSig + 1});
        }
        else{
            let Detalle = this.state.Detalle;
            const indice = this.state.indice;
            Detalle[indice].Codigo = this.state.codigo;
            Detalle[indice].Nombre = this.state.nombre;
            Detalle[indice].Cantidad = this.state.cantidad;
            Detalle[indice].Costo = this.state.costo;
            Detalle[indice].Importe = this.state.importe;
            Detalle[indice].Lote = this.state.lote;
            this.setState({mostrarModal:false, cambio:true});
        }
    }
    codigoIns = (item) =>{
        this.setState({popoverOpen:false});
        this.cargarArticulo(item.I);
    }
    cargarArticulo = async (cve) => {
        this.setState({cargando:true});
        const url = this.props.urlws + "/articulo/cargar/" + cve;
        const resp = await fetch(url);
        const articulo = await resp.json();
        if(articulo.Depto === 0){
            Swal.fire('Atencion','Codigo no registrado','error');
            this.setState({cargando:false});
            return;
        }
         this.setState({
            cargando:false,
            codigo:articulo.Codigo,
            nombre:articulo.Nombre,
            costo:articulo.Costo,
            importe:articulo.Costo * this.state.cantidad,
            articulo
        });
        document.getElementById("cantidad").focus();
    }
    agregarDetalle = () =>{
        this.setState({mostrarModal:true, renglon : 0});
    }
    editarRenglon = (ren,i) =>{
        this.setState({
            mostrarModal:true,
            renglon:ren.Renglon,
            codigo:ren.Codigo,
            nombre:ren.Nombre,
            cantidad:ren.Cantidad,
            costo:ren.Costo,
            importe:ren.Importe,
            indice:i
        });
    }
    cancelar = () =>{
        if(!this.state.cambio){
            this.props.cancelar();
            return;
        }
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea cancelar la captura ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.cancelar();
            }
          })        
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar este movimiento ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state);
            }
          })        
    }
    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col-2">
                    <FormGroup>
                        <label>Numero</label>
                        <Input size="sm" type="text" name="Numero" value={this.state.Numero} />
                    </FormGroup>
                    </div>
                    <div className="col-3">
                    <FormGroup >
                        <label>Fecha</label>
                        <Input size="sm" type="date" name="Fecha" value={this.state.Fecha} onChange={this.onChange} />
                    </FormGroup>
                    </div>
                    <div className="col-7">
                    <FormGroup >
                        <label>Concepto</label>
                        <Input size="sm" type="text" name="Concepto" value={this.state.Concepto} onChange={this.onChange} />
                    </FormGroup>
                    </div>
                </div>
                <div style={{ height: 4 }}></div>
                <div style={{ height: this.props.height, overflow: 'auto' }}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Codigo</th>
                                <th>Nombre</th>
                                <th className="numero">Cantidad</th>
                                <th className="numero">Costo</th>
                                <th className="numero">Importe</th>
                                <th style={{width:30}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Detalle.map((ren, i) => (
                                <DetalleRow key={i} indice={i} renglon={ren} onRowClick={this.editarRenglon}/>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="col">
                    <Button size="sm" color="success" onClick={this.guardar}>Guardar</Button>
                    <span>&nbsp;&nbsp;</span>
                    <Button size="sm" color="danger" onClick={this.cancelar}>Cancelar</Button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button size="sm" color="success" onClick={this.agregarDetalle}>+</Button>
                </div>

                <Modal id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} size="lg" tabIndex={1}>
                    <ModalBody>
                        <div className="row">
                            <div className="col">
                                <FormGroup md="3" >
                                    <label>Codigo &nbsp;</label>
                                    <InputGroup>
                                        <Input size="sm" type="text" name="codigo" value={this.state.codigo} onChange={this.onChange} onKeyPress={this.onKeyPressCodigo} id="codigo" autoFocus />
                                        <InputGroupAddon addonType="append">
                                            <Button size="sm" color="success" id="btnBuscar">
                                                <img src="/imagenes/zoom.png" alt="buscar" title="buscar" />
                                            </Button>
                                            <Popover placement="right" isOpen={this.state.popoverOpen} target="btnBuscar" toggle={this.toggle} >
                                                <PopoverBody>
                                                    <Buscador onSelect={this.codigoIns} url={this.props.urlws + "/articulo/todos?filtro="} />
                                                </PopoverBody>
                                            </Popover>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup md="4">
                                    <label>Nombre &nbsp;</label>
                                    <Input size="sm" type="text" value={this.state.nombre} style={{ width: 600 }} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2">
                                <FormGroup>
                                    <label>Cantidad </label>
                                    <Input size="sm" type="text" id="cantidad" name="cantidad" value={this.state.cantidad} onChange={this.onChange} onKeyPress={(e) => {if(e.which == 13) this.enter("cantidad");}} id="cantidad"/>
                                </FormGroup>
                            </div> 
                            <div className="col-2">
                                <FormGroup>
                                <label>&nbsp;</label><br/>
                                <Button size="sm" color='success' onClick={this.agregarRenglon}>+</Button>
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

class DetalleRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.renglon, this.props.indice);
    }
    render(){
        let {renglon} = this.props;
        return (
        <tr>
            <td>{renglon.Codigo}</td>
            <td>{renglon.Nombre}</td>
            <td className="numero">{renglon.Cantidad.formato()}</td>
            <td className="numero">{renglon.Costo.formato()}</td>
            <td className="numero">{renglon.Importe.formato()}</td>
            <td>
                <img src="/imagenes/pencil.png"  onClick={this.onClick} alt="editar" title="editar"/>
            </td>
        </tr>
        );
    }
}