import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Nominas extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            detalle:false,
            mostrarModal:false,
            empresa:1,
            tipo:'O',
            ejercicio:2020,
            fechaIni:fecIni,
            fechaFin:fecFin,
            fechaPago:fecFin,
            nueva:false,
            empresas:[],
            tipos:[{I:'O',N:'ORDINARIA'},{I:'E',N:'EXTRAORDINARIA'}],
            nominas:[],
            usuarios:[],
            ejercicios:[],
            temporadas:[],
            recibos:[],
            calculos:[],
            nomina:0,
            objNomina:{}
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/empresa/empresas`;
        let resp = await fetch(url);
        const empresas = await resp.json();
        url = `${this.props.urlws}/conta/ejercicios`;
        resp = await fetch(url);
        const ejercicios = await resp.json();
        this.setState({cargando:false, empresas, ejercicios });
    }
    cargarMovs = async () =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        if(emp === 0){
            Swal.fire('Atencion','Especifique la empresa','error');
            return;
        }
        if(emp === 'X'){
            Swal.fire('Atencion','Especifique el tipo de nomina','error');
            return;
        }
        if(ejer === 0){
            Swal.fire('Atencion','Especifique el año','error');
            return;
        }
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/nominas?emp=${emp}&ejer=${ejer}&tipo=${tipo}`;
        let resp = await fetch(url);
        const nominas = await resp.json();
        this.setState({cargando:false, nominas });
    }
    guardar = async (obj) =>{
        const mov = {
            Empresa : this.state.empresa,
            Ejercicio : this.state.ejercicio,
            Nomina : this.state.nomina,
            Tipo : this.state.tipo,
            Inicio : this.state.fechaIni,
            Fin : this.state.fechaFin,
            FechaPago : this.state.fechaPago
        }
        const url = this.props.urlws +"/nomina/wsguardarNomina";
        this.setState({cargando:true});
        const json = JSON.stringify(mov);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.setState({editando:false,mostrarModal:false});
            this.cargarMovs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    handleClose = () =>{
        this.setState({mostrarModal:false});
    }
    regresar = () =>{
        this.setState({detalle:false});
    }
    editarMov = (mov) =>{
        let nomina = mov.Nomina;
        let fechaIni = mov.Inicio;
        let fechaFin = mov.Fin;
        let fechaPago = mov.FechaPago;
        let nueva = false;
        if(fechaPago === '')
           fechaPago = mov.Fin;
        this.setState({mostrarModal:true, fechaIni, fechaFin,fechaPago, nomina, nueva});
    }
    imprimir = (mov) =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        let url = this.props.urlws + `/nomina/imprimirNominaAcum?emp=${emp}&ejer=${ejer}&nom=${mov.Nomina}&tipo=${tipo}`;
        window.open(url, '', '');    
    }
    timbrar = async(rec) =>{
        const emp = this.state.empresa;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/timbrar?emp=${emp}&ser=${rec.Ser}&num=${rec.Rec}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Se timbro el recibo ' + rec.Rec,'success');
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    cambiarStatus = async(mov) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Cerrar la nomina " + mov.Nomina +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(result.value)
                this.acumular(mov);
          })        
    }
    acumular = async(mov) => {
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        this.setState({cargando:true});
        let url = this.props.urlws + `/nomina/acumular?emp=${emp}&ejer=${ejer}&nom=${mov.Nomina}&tipo=${tipo}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Se acumulo la nomina ' + mov.Nomina,'success');
            this.cargarMovs();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    eliminarEmp = async(emp) =>{
        this.setState({cargando:true});
        let url = `${this.props.urlws}/nomina/eliminarCaptura?empresa=${this.state.empresa}&empleado=${emp.Id}`;
        let resp = await fetch(url);
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Se elimino de la nomina al empleado ' + emp.Nom,'success');
            this.detalleMov(this.state.objNomina);
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    detalleMov = async(mov) =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        this.setState({cargando:true});
        if(mov.Cerrada){
            let url = `${this.props.urlws}/nomina/recibos?emp=${emp}&ejer=${ejer}&nom=${mov.Nomina}&tipo=${tipo}`;
            let resp = await fetch(url);
            const recibos = await resp.json();
            this.setState({cargando:false, detalle:true, recibos, objNomina:mov });
        }else{
            let url = `${this.props.urlws}/nomina/cargarNomCalcTot/${emp}`;
            let resp = await fetch(url);
            const calculos = await resp.json();
            this.setState({cargando:false, detalle:true, calculos, objNomina:mov });
        }
    }
    nuevoMov = () =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const ejer = this.state.ejercicio;
        if(emp === 0){
            Swal.fire('Atencion','Especifique la empresa','error');
            return;
        }
        if(emp === 'X'){
            Swal.fire('Atencion','Especifique el tipo de nomina','error');
            return;
        }
        if(ejer === 0){
            Swal.fire('Atencion','Especifique el año','error');
            return;
        }
        let nomina = 0;
        let fec = new Date();
        fec.addDays(15);
        let fechaFin = fec.yyyymmdd('-');
        let fechaIni = new Date().yyyymmdd('-');
        let nueva = true;
        this.setState({mostrarModal:true, fechaIni, fechaFin, nomina, nueva});
    }
    render(){
        const estilo1 = { display: this.state.detalle ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.detalle ? 'none' : 'inline' };
        return (
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                <span></span>
                }
                <div style={estilo1}>
                    {this.state.objNomina.Cerrada ?
                        <Recibos recibos={this.state.recibos} nomina={this.state.objNomina} regresar={this.regresar} timbrar={this.timbrar}/>
                    :
                        <Calculos calculos={this.state.calculos} regresar={this.regresar} eliminar={this.eliminarEmp}/>
                    }
                </div>
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-4">
                            <FormGroup>
                                <span>Empresa</span><br />
                                <Input size="sm" type="select" name="empresa" value={this.state.empresa} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.empresas.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Año</span><br />
                                <Input size="sm" type="select" name="ejercicio" value={this.state.ejercicio} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value={0}>Especifique</option>
                                    {this.state.ejercicios.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Tipo</span><br />
                                <Input size="sm" type="select" name="tipo" value={this.state.tipo} onChange={this.onChange} style={{ fontSize: '12px' }}>
                                    <option key="0" value="X">Especifique</option>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoMov}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable movs={this.state.nominas} height={500} width={990} onRowClick={this.editarMov} detalle={this.detalleMov} imprimir={this.imprimir} cambiarStatus={this.cambiarStatus} />
                </div>
                <Modal size="sm" id="dlg" name="dlg" isOpen={this.state.mostrarModal} toggle={this.handleClose} tabIndex={1}>
                    <ModalBody>
                        {this.state.nomina === 0 ?
                            <h5>Datos de la nueva nomina</h5>
                            :
                            <h5>Datos de la nomina {this.state.nomina} </h5>
                        }
                        <div className="col-2">

                        </div>
                        <div className="col-8">
                            <div className="row">
                                <span>Del dia</span>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </div>
                            <div className="row">
                                <span>Al dia</span>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </div>
                            {
                                this.state.nueva?
                                <span></span>
                                :
                                <div className="row">
                                <span>Fecha de pago</span>
                                <Input size="sm" type="date" name="fechaPago" value={this.state.fechaPago} onChange={this.onChange} />
                            </div>
                            }
                        </div>
                        <br/>
                        <div className="row">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color='success' size="sm" onClick={this.guardar}>Aceptar</Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button color='danger' size="sm" onClick={this.handleClose}>Cancelar</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { movs } = this.props;
        return (
            <React.Fragment>
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50, textAlign:'center'}}>Nomina</th>
                        <th>Inicio</th>
                        <th>Fin</th>
                        <th>Pago</th>
                        <th>Cerrada</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {movs.map((esc, i) => (
                        <MovRow key={i} mov={esc} onRowClick={this.onRowClick} detalle={this.props.detalle} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} />
                    ))}
                </tbody>
                </Table>
            </div>
            </React.Fragment>
        );
    }
}
class MovRow extends React.Component{
    onClick = (e) =>{
        if(this.props.mov.Cerrada){
            Swal.fire('Atencion','Nomina cerrada, no se puede editar','error');
            return;
        }
        this.props.onRowClick(this.props.mov);
    }
    detalle = (e) =>{
        this.props.detalle(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    cambiar = (e) =>{
        this.props.cambiarStatus(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Nomina}</td>
            <td>{mov.Inicio}</td>
            <td>{mov.Fin}</td>
            <td>{mov.Pago}</td>
            <td><input type="checkbox" checked={mov.Cerrada} /></td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" onClick={this.onClick}/>
            </td>
            <td>
                <img src="/imagenes/tabla.png" alt="empleados" title="empleados" onClick={this.detalle}/>
            </td>
            <td>
                <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
            </td>
            <td>
                {this.props.mov.Cerrada ?
                <span></span>
                :
                <img src="/imagenes/candado.png" alt="cerrar" title="cerrar" onClick={this.cambiar}/>
                }
            </td>
        </tr>
        );
    }
}
class Recibos extends React.Component {
    render() {
        let { recibos } = this.props;
        return (
            <React.Fragment>
            <span>Recibos de pago de la nomina {this.props.nomina.Nomina}  &nbsp;&nbsp;&nbsp;&nbsp;   <Button size="sm" color="danger" onClick={this.props.regresar}>Regresar</Button></span>
            <br/><br/>
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50, textAlign:'center'}}>Serie</th>
                        <th style={{width:50, textAlign:'center'}}>Folio</th>
                        <th>Empleado</th>
                        <th style={{width:100, textAlign:'right'}}>Importe</th>
                        <th style={{width:80}}>Fec.Pago</th>
                        <th style={{width:70}}>Timbrado</th>
                        <th style={{width:250}}>UUID</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {recibos.map((esc, i) => (
                        <ReciboRow key={i} mov={esc} onRowClick={this.onRowClick} detalle={this.props.detalle} imprimir={this.props.imprimir} cambiarStatus={this.props.cambiarStatus} timbrar={this.props.timbrar}/>
                    ))}
                </tbody>
                </Table>
            </div>
            </React.Fragment>
        );
    }
}
class ReciboRow extends React.Component{
    onClick = (e) =>{
        if(this.props.mov.Cerrada){
            Swal.fire('Atencion','Nomina cerrada, no se puede editar','error');
            return;
        }
        this.props.onRowClick(this.props.mov);
    }
    timbrar = (e) =>{
        this.props.timbrar(this.props.mov);
    }
    imprimir = (e) =>{
        this.props.imprimir(this.props.mov);
    }
    cambiar = (e) =>{
        this.props.cambiarStatus(this.props.mov);
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Ser}</td>
            <td>{mov.Rec}</td>
            <td>{mov.Nom}</td>
            <td className="numero">{mov.Tot}</td>
            <td>{mov.Pago}</td>
            <td><input type="checkbox" checked={mov.Timb} /></td>
            <td>{mov.Uuid}</td>
            <td>
                {mov.Timb ?
                    <img src="/imagenes/printer.png" alt="imprimir" title="imprimir" onClick={this.imprimir}/>
                :
                    <img src="/imagenes/xml.png" alt="timbrar" title="timbrar" onClick={this.timbrar}/>
                }
            </td>
            <td>
                {mov.Timb ?
                    <img src="/imagenes/email.png" alt="enviar" title="enviar" onClick={this.enviar}/>
                :
                    <span></span>
                }
            </td>
        </tr>
        );
    }
}
class Calculos extends React.Component {
    render() {
        let { calculos } = this.props;
        return (
            <React.Fragment>
            <span>Calculo de la nomina actual   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button size="sm" color="danger" onClick={this.props.regresar}>Regresar</Button></span>
            <br/><br/>
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50, textAlign:'center'}}>No.</th>
                        <th>Nombre</th>
                        <th style={{width:100, textAlign:'right'}}>Percepciones</th>
                        <th style={{width:100, textAlign:'right'}}>Deducciones</th>
                        <th style={{width:100, textAlign:'right'}}>Total</th>
                        <th style={{width:30, textAlign:'center'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {calculos.map((esc, i) => (
                        <CalculoRow key={i} mov={esc} onRowClick={this.props.eliminar} />
                    ))}
                </tbody>
                </Table>
            </div>
            </React.Fragment>
        );
    }
}
class CalculoRow extends React.Component{
    onClick = async(e) =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Eliminar de esta nomina al empleado " + this.props.mov.Nom +" ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then(async(result) => {
              if(result.value)
                this.props.onRowClick(this.props.mov);
          })        
    }
    render(){
        let {mov} = this.props;
        return (
        <tr>
            <td>{mov.Id}</td>
            <td>{mov.Nom}</td>
            <td className="numero">{mov.Per.formato()}</td>
            <td className="numero">{mov.Ded.formato()}</td>
            <td className="numero">{mov.Tot.formato()}</td>
            <td>
                <img src="/imagenes/quitar.png" alt="eliminar" title="eliminar" onClick={this.onClick}/>
            </td>
        </tr>
        );
    }
}
