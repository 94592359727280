import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Collapse, Nav, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class CotizaPedido extends React.Component {
    constructor(props) {
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:true,
            formVisible: false,
            fechaIni:fecIni,
            fechaFin:fecFin,
            clave: 'PE',
            nombre:'?',
            dato:{},
            datos: [],
            tipos:[],
            clientes:[],
            usuarios:[],
            tiposEnvio:[],
        };
        this.cargarCatalogos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/cliente/clientes`;
        let resp = await fetch(url);
        const clientes = await resp.json();

        url = `${this.props.urlws}/cliente/tiposPedidos`;
        resp = await fetch(url);
        const tipos = await resp.json();

        url = `${this.props.urlws}/cliente/tiposEnvio`;
        resp = await fetch(url);
        const tiposEnvio = await resp.json();

        url = `${this.props.urlws}/usuario/usuarios`;
        resp = await fetch(url);
        const usuarios = await resp.json();

        this.setState({cargando:false, clientes, tipos, tiposEnvio, usuarios });
        this.cargarDatos();
    }
    cargarDatos = async () => {
        let {clave} = this.state;
        if(clave === undefined){
            clave = 'PE';
            this.state.clave = clave;
        }
        if(clave === ''){
            Swal.fire('Atencion','Especifique el tipo de pedido','error');
            return;
        }
        this.setState({cargando:true});
        const url = `${this.props.urlws}/cliente/pedidos?cve=${clave}&fecIni=${this.state.fechaIni}&fecFin=${this.state.fechaFin}`;
        const resp = await fetch(url);
        const datos = await resp.json();
        this.setState({cargando:false, datos });
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarDatos();
    }    
    cargarDato = async (e) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/cliente/cargarPedido?cve=${e.Cve}&num=${e.Num}`;
        const resp = await fetch(url);
        const dato = await resp.json();
        if(dato.Numero === 0){
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: e.Clave,
            dato
        });
    }
    guardarDato = async(dato) =>{
        const url = this.props.urlws +"/cliente/wsguardarDetPedCot";
        this.setState({cargando:true});
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.cargarDato(dato);
            //this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoDato = () => {
        const {clave} = this.state;
        if(clave === ''){
            Swal.fire('Atencion','Especifique el tipo de pedido','error');
            return;
        }
        const nuevo = { Cve:this.state.clave,Num : 0}
        this.cargarDato(nuevo);
    }
    imprimir = (mov) =>{
        let params = `?cve=${mov.Cve}&num=${mov.Num}`;
        let url = this.props.urlws + '/cliente/imprimirPedidoCot' + params;
        window.open(url, '', '');
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container>
                {this.state.cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="primary" />
                    </div>
                    :
                    null
                }
                <div style={estilo}>
                    <div className="row">
                        <div className="col-4">
                            <FormGroup as={Col} >
                                <span>Cliente</span><br/>
                                <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange} >
                                    <option key={-1} value={''}>Especifique</option>
                                    {this.state.tipos.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br />
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br />
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br />
                                <Button color='success' size="sm" onClick={this.cargarDatos}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoDato}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <DatosTable datos={this.state.datos} height={500} width={990} onRowClick={this.cargarDato} imprimir={this.imprimir} />
                </div>
                <DatoForm visible={this.state.formVisible} dato={this.state.dato} clientes={this.state.clientes} 
                   onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarDato} urlws={this.props.urlws} 
                   tiposEnvio={this.state.tiposEnvio} usuarios={this.state.usuarios}
                   />
            </Container>
        );
    }
}

class DatosTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { datos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50}}>Numero</th>
                        <th style={{width:100}}>Fecha</th>
                        <th style={{width:100}}>Entrega</th>
                        <th>Cliente</th>
                        <th>Concepto</th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th>Verificado</th>
                        <th>Cotizado</th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map((esc, i) => (
                        <DatoRow key={i} dato={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir}/>
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class DatoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.dato);
    }
    onImprimir = (e) =>{
        this.props.imprimir(this.props.dato);
    }
    onCotizar = (e) =>{
        alert("Proceso en desarrollo");
        ///this.props.imprimir(this.props.dato);
    }
    render(){
        let {dato} = this.props;
        return (
        <tr>
            <td>{dato.Num}</td>
            <td>{dato.Fec}</td>
            <td>{dato.FecEnt}</td>
            <td>{dato.Cte}</td>
            <td>{dato.Con}</td>
            <td style={{textAlign:'center', verticalAlign:'middle'}}>
                <img src="/imagenes/pencil.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
            <td style={{textAlign:'center', verticalAlign:'middle'}}>
                <img src="/imagenes/printer.png" onClick={this.onImprimir} alt="imprimir" title="imprimir"/>
            </td>
            <td style={{textAlign:'center', verticalAlign:'middle'}}>
                {dato.Status > 1 ?
                <img src="/imagenes/ok.png" />
                :
                <img src="/imagenes/cross.png" />
                }
            </td>
            <td style={{textAlign:'center', verticalAlign:'middle'}}>
                {dato.Status === 3 ?
                <img src="/imagenes/ok.png" />
                :
                <img src="/imagenes/cross.png" />
                }
            </td>
        </tr>
        );
    }
}

class DatoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
            dato:this.props.dato,
            detalle:this.props.dato.Detalle,
            capturarProducto:false,
            codigoProd:'',
            nombreProd:'',
            texto:'',
            cantidad:0
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            dato:newProps.dato,
            detalle:newProps.dato.Detalle
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.dato,
            [e.target.name] : e.target.value
        };
        this.setState({dato:esc});
    }
    onChange2 = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    handleClose = () =>{
        this.setState({capturarProducto:false});
    }
    guardar = (dato,d,f,o) => {
        dato.PreCot = d;
        dato.FecEntCot = f;
        dato.TxtCot = o;
        this.setState({popoverOpen: false});
        this.props.guardar(dato);
    }    
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.dato === undefined)
            return null;
        if(Object.keys(this.props.dato).length === 0)
            return null;
        const estiloDet = { display: this.props.dato.Numero > 0 ? 'inline' : 'none'};
        return (
            <Container style={estilo}>
                <Form>
                    <div style={{width:900}}>
                        <div className="row">
                            <div className="col-1">
                                <FormGroup >
                                    <label>Numero</label>
                                    <Input size="sm" type="text" name="Numero" value={this.state.dato.Numero} style={{ width: 50 }} />
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup>
                                    <label>Concepto</label>
                                    <Input size="sm" type="text" name="Concepto" value={this.state.dato.Concepto}/>
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <label>Fecha</label>
                                    <Input size="sm" type="date" name="Fecha" value={this.state.dato.Fecha}/>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <FormGroup >
                                    <label size="sm">Cliente</label>
                                    <Input size="sm" type="select" name="Cliente" value={this.state.dato.Cliente} onChange={this.onChange} >
                                        <option key={-1} value={-1}>Especifique</option>
                                        {this.props.clientes.map((i, k) => (
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </Form>
                <br />
                <div style={estiloDet}>
                <h5>Productos</h5>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Nombre</th>
                            <th>Cantidad</th>
                            <th>Disponible</th>
                            <th style={{ width: 80 }}>Fec.Ent.Prod</th>
                            <th style={{ width: 80 }}>Precio</th>
                            <th style={{ width: 80 }}>Fec.Ent.Cot</th>
                            <th style={{ width: 30 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.detalle.map((i, k) => (
                            <DetalleRow i={i} k={k} guardar={this.guardar}/>
                        ))}
                    </tbody>
                </Table>
                <Button size="sm" color="danger" onClick={this.props.onClose}>Regresar</Button>
                </div>
            </Container>
        );
    }
}

class DetalleRow extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            popoverOpen:false,
        }
    }
    guardar = (dato,d,f,o) => {
        this.props.guardar(dato,d,f,o);
    }    
    toggle = () => {
        this.setState({popoverOpen: !this.state.popoverOpen});
    }    
    onClick = (e) =>{
        this.toggle();
    }
    render(){
        return(
            <tr key={this.props.i.Ren}>
            <td>{this.props.i.Cod}</td>
            <td>{this.props.i.Nom}</td>
            <td>{this.props.i.Can}</td>
            <td>{this.props.i.Disp}</td>
            <td>{this.props.i.FecEntProd}</td>
            <td>{Number(this.props.i.PreCot).formato()}</td>
            <td>{this.props.i.FecEntCot}</td>
            <td>
                <img src="/imagenes/edit.png" alt="editar" title="editar" id={"img" + this.props.k} onClick={this.onClick}/>
                <Popover placement="left" isOpen={this.state.popoverOpen} target={"img" + this.props.k} toggle={this.toggle} trigger="legacy">
                    <PopoverBody>
                        < Disponibilidad dato={this.props.i} guardar={this.guardar} />
                    </PopoverBody>
                </Popover>
            </td>
        </tr>
        )
    }
}

class Disponibilidad extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            precio:this.props.dato.PreCot,
            fechaEnt:this.props.dato.FecEntCot,
            observaciones:this.props.dato.TxtCot
        };
    }
    componentWillReceiveProps(props){
        this.setState({
            precio:props.dato.PreCot,
            fechaEnt:props.dato.FecEntCot,
            observaciones:props.dato.TxtCot
        });
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    onChangeCh = (e) => {
        const campo = e.target.name;
        const valor = e.target.checked;
        this.setState({ [campo] : valor});
    }
    guardar = () =>{
        this.props.guardar(this.props.dato,this.state.precio,this.state.fechaEnt,this.state.observaciones);
    }
    render(){
        return(
            <div className="container">
                <div className="row">
                    <div className="col">
                        <FormGroup>
                            <label>Precio&nbsp;</label>
                            <Input size="sm" type="text" name="precio" value={this.state.precio} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <label>Fecha de entrega</label><br/>
                            <Input size="sm" type="date" name="fechaEnt" value={this.state.fechaEnt} onChange={this.onChange} />
                       </FormGroup>
                    </div>
                </div>
                <FormGroup  >
                    <label>Observaciones</label>
                    <textarea class="form-control" rows="5" name="observaciones" value={this.state.observaciones} onChange={this.onChange}></textarea>
                </FormGroup>
                <Button color="success" onClick={this.guardar}>Aceptar</Button>
            </div>
        )
    }
}