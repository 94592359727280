import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent,TabPane, Modal, ModalHeader, ModalBody,Collapse,InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class XmlProvs extends React.Component{
    constructor(props){
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:false,
            editando:false,
            tipo:'I',
            empresa:1,
            fechaIni:fecIni,
            fechaFin:fecFin,
            tipos:[],
            xmls:[]
        };
        this.cargarCatalogos();
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/tiposXml`;
        let resp = await fetch(url);
        const tipos = await resp.json();
        this.setState({cargando:false, tipos });
    }
    cargarMovs = async () =>{
        const emp = this.state.empresa;
        const tipo = this.state.tipo; 
        const fecIni = this.state.fechaIni;
        const fecFin = this.state.fechaFin;
        this.setState({cargando:true});
        let url = `${this.props.urlws}/prov/listadoXML?emp=${emp}&tipo=${tipo}&fecIni=${fecIni}&fecFin=${fecFin}`;
        let resp = await fetch(url);
        const xmls = await resp.json();
        this.setState({cargando:false, xmls });
    }
    subir = async() => {
        const { value: file } = await Swal.fire({
            title: 'Procesar factura electronica',
            input: 'file',
            inputAttributes: {
              accept: 'xml/*',
              'aria-label': 'Seleccione archivo XML'
            }
          });
          
          if (file) {
              this.setState({cargando:true});
            const data = new FormData()
            data.append('file', file);
            data.append('id', 0);    
            const resp = await fetch(this.props.urlws + "/home/subirArchivo",{
                method: 'POST', // or 'PUT'
                body: data // data can be `string` or {object}!
              });
            const res = await resp.text();
            this.setState({cargando:false});
            if(res === "ok"){
                Swal.fire('Atencion',"Se guardo el archivo seleccionado",'success');
                this.cargarMovs();
            }
            else
                Swal.fire('Atencion',res,'error');        
    
          }        
    }
    onChange = (e) => {
        const campo = e.target.name;
        const valor = e.target.value;
        this.setState({ [campo] : valor});
    }
    render(){
        //const estilo1 = { display: this.state.editando ? 'inline' : 'none' };
        const estilo2 = { padding:2, display: this.state.editando ? 'none' : 'inline' };
        return(
            <Container>
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div style={estilo2}>
                    <div className="row">
                        <div className="col-3">
                            <FormGroup>
                                <span>Tipo</span><br/>
                                <Input size="sm" type="select" name="tipo" value={this.state.tipo} onChange={this.onChange}>
                                    <option key="0" value="??">Especifique</option>
                                    {this.state.tipos.map((i, k) =>
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br/>
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br/>
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br/>
                                <Button color='success' size="sm" onClick={this.cargarMovs}>Cargar</Button>
                                &nbsp;
                                &nbsp;
                                <Button color='warning' size="sm" onClick={this.subir}>Subir Archivos</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <MovsTable xmls={this.state.xmls} height={500} width={990}  />
                </div>                
            </Container>
        )
    }
}
class MovsTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { xmls } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Folio</th>
                        <th>Fecha</th>
                        <th>Proveedor</th>
                        <th>Metodo de pago</th>
                        <th>Forma de pago</th>
                        <th className="numero">Importe</th>
                        <th>Cargado</th>
                    </tr>
                </thead>
                <tbody>
                        {xmls.map((xml, i) => (
                            <tr>
                                <td>{xml.Fol}</td>
                                <td>{xml.Fec}</td>
                                <td>{xml.Prov}</td>
                                <td>{xml.Metodo}</td>
                                <td>{xml.Forma}</td>
                                <td className="numero">{xml.Tot}</td>
                                <td><input type="checkbox" checked={xml.Cargado}/></td>
                            </tr>
                        ))}
                </tbody>
                </Table>
            </div>
        );
    }
}