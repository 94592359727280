import React from 'react';
import { Navbar,NavbarBrand, Table, Form, FormGroup, Col, Input, Button, Container, Spinner, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Collapse, Nav, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default class Pedidos extends React.Component {
    constructor(props) {
        super(props);
        let fec = new Date();
        fec.addDays(-7);
        let fecIni = fec.yyyymmdd('-');
        let fecFin = new Date().yyyymmdd('-');
        this.state = {
            cargando:true,
            formVisible: false,
            fechaIni:fecIni,
            fechaFin:fecFin,
            clave: 'PE',
            nombre:'?',
            dato:{},
            datos: [],
            tipos:[],
            clientes:[],
            usuarios:[],
            tiposEnvio:[],
        };
        this.cargarCatalogos();
    }
    onFormClose = () => {
        this.setState({
            formVisible: false
        });
    }
    cargarCatalogos = async () => {
        this.setState({cargando:true});
        let url = `${this.props.urlws}/cliente/clientes`;
        let resp = await fetch(url);
        const clientes = await resp.json();

        url = `${this.props.urlws}/cliente/tiposPedidos`;
        resp = await fetch(url);
        const tipos = await resp.json();

        url = `${this.props.urlws}/cliente/tiposEnvio`;
        resp = await fetch(url);
        const tiposEnvio = await resp.json();

        url = `${this.props.urlws}/usuario/usuarios`;
        resp = await fetch(url);
        const usuarios = await resp.json();

        this.setState({cargando:false, clientes, tipos, tiposEnvio, usuarios });
        this.cargarDatos();
    }
    cargarDatos = async () => {
        let {clave} = this.state;
        if(clave === undefined){
            clave = 'PE';
            this.state.clave = clave;
        }
        if(clave === ''){
            Swal.fire('Atencion','Especifique el tipo de pedido','error');
            return;
        }
        this.setState({cargando:true});
        const url = `${this.props.urlws}/cliente/pedidos?cve=${clave}&fecIni=${this.state.fechaIni}&fecFin=${this.state.fechaFin}`;
        const resp = await fetch(url);
        const datos = await resp.json();
        this.setState({cargando:false, datos });
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarDatos();
    }    
    cargarDato = async (e) => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/cliente/cargarPedido?cve=${e.Cve}&num=${e.Num}`;
        const resp = await fetch(url);
        const dato = await resp.json();
        if(dato.Numero === 0){
         }
         this.setState({
            cargando:false,
            formVisible: true,
            clave: e.Clave,
            dato
        });
    }
    guardarDato = async(dato) =>{
        const url = this.props.urlws +"/cliente/wsguardarPedido";
        this.setState({cargando:true});
        const json = JSON.stringify(dato);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            Swal.fire('Atencion','Datos guardados','success');
            this.onFormClose();
        }
        else
            Swal.fire('Atencion',res,'error');
    }
    nuevoDato = () => {
        const {clave} = this.state;
        if(clave === ''){
            Swal.fire('Atencion','Especifique el tipo de pedido','error');
            return;
        }
        const nuevo = { Cve:this.state.clave,Num : 0}
        this.cargarDato(nuevo);
    }
    imprimir = (mov) =>{
        let params = `?cve=${mov.Cve}&num=${mov.Num}`;
        let url = this.props.urlws + '/cliente/imprimirPedido' + params;
        window.open(url, '', '');
    }
    render() {
        const estilo = { display: this.state.formVisible ? 'none' : 'inline' };
        return (
            <Container>
                {this.state.cargando ?
                    <div className="loader">
                        <Spinner animation="border" color="primary" />
                    </div>
                    :
                    null
                }
                <div style={estilo}>
                    <div className="row">
                        <div className="col-4">
                            <FormGroup as={Col} >
                                <span>Cliente</span><br/>
                                <Input size="sm" type="select" name="clave" value={this.state.clave} onChange={this.onChange} >
                                    <option key={-1} value={''}>Especifique</option>
                                    {this.state.tipos.map((i, k) => (
                                        <option key={i.I} value={i.I}>{i.N}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>Del dia</span><br />
                                <Input size="sm" type="date" name="fechaIni" value={this.state.fechaIni} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <span>al dia</span><br />
                                <Input size="sm" type="date" name="fechaFin" value={this.state.fechaFin} onChange={this.onChange} />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup as={Col}>
                                <span>&nbsp;</span><br />
                                <Button color='success' size="sm" onClick={this.cargarDatos}>Cargar</Button>
                                {'  '}
                                <Button color='success' size="sm" onClick={this.nuevoDato}>+</Button>
                            </FormGroup>
                        </div>
                    </div>
                    <div style={{ height: 4 }}></div>
                    <DatosTable datos={this.state.datos} height={500} width={990} onRowClick={this.cargarDato} imprimir={this.imprimir} />
                </div>
                <DatoForm visible={this.state.formVisible} dato={this.state.dato} clientes={this.state.clientes} 
                   onClose={this.onFormClose} clave={this.state.clave} guardar={this.guardarDato} urlws={this.props.urlws} 
                   tiposEnvio={this.state.tiposEnvio} usuarios={this.state.usuarios}
                   />
            </Container>
        );
    }
}

class DatosTable extends React.Component {
    onRowClick = (cve) => {
        this.props.onRowClick(cve);
    }
    render() {
        let { datos } = this.props;
        return (
            <div style={{ height: this.props.height, width:this.props.width, overflow: 'auto' }}>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{width:50}}>Numero</th>
                        <th style={{width:100}}>Fecha</th>
                        <th style={{width:100}}>Entrega</th>
                        <th>Cliente</th>
                        <th>Concepto</th>
                        <th style={{width:30}}></th>
                        <th style={{width:30}}></th>
                        <th>Verificado</th>
                        <th>Cotizado</th>
                    </tr>
                </thead>
                <tbody>
                    {datos.map((esc, i) => (
                        <DatoRow key={i} dato={esc} onRowClick={this.onRowClick} imprimir={this.props.imprimir}/>
                    ))}
                </tbody>
                </Table>
            </div>
        );
    }
}

class DatoRow extends React.Component{
    onClick = (e) =>{
        this.props.onRowClick(this.props.dato);
    }
    onImprimir = (e) =>{
        this.props.imprimir(this.props.dato);
    }
    onCotizar = (e) =>{
        alert("Proceso en desarrollo");
        ///this.props.imprimir(this.props.dato);
    }
    render(){
        let {dato} = this.props;
        return (
        <tr>
            <td>{dato.Num}</td>
            <td>{dato.Fec}</td>
            <td>{dato.FecEnt}</td>
            <td>{dato.Cte}</td>
            <td>{dato.Con}</td>
            <td style={{textAlign:'center', verticalAlign:'middle'}}>
                <img src="/imagenes/pencil.png" onClick={this.onClick} alt="editar" title="editar"/>
            </td>
            <td style={{textAlign:'center', verticalAlign:'middle'}}>
                <img src="/imagenes/printer.png" onClick={this.onImprimir} alt="imprimir" title="imprimir"/>
            </td>
            <td style={{textAlign:'center', verticalAlign:'middle'}}>
                {dato.Status > 1 ?
                <img src="/imagenes/ok.png" />
                :
                <img src="/imagenes/cross.png" />
                }
            </td>
            <td style={{textAlign:'center', verticalAlign:'middle'}}>
                {dato.Status === 3 ?
                <img src="/imagenes/ok.png" />
                :
                <img src="/imagenes/cross.png" />
                }
            </td>
        </tr>
        );
    }
}

class DatoForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            //...this.props.alumno,
           dato:this.props.dato,
            detalle:this.props.dato.Detalle,
            capturarProducto:false,
            codigoProd:'',
            nombreProd:'',
            texto:'',
            cantidad:0
        }
    }
    componentWillReceiveProps(newProps){
        this.setState({
            dato:newProps.dato,
            detalle:newProps.dato.Detalle
        });
    }
    onChange = (e) => {
        const esc = {
            ...this.state.dato,
            [e.target.name] : e.target.value
        };
        this.setState({dato:esc});
    }
    onChange2 = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    handleClose = () =>{
        this.setState({capturarProducto:false});
    }
    nuevoProducto = () =>{
        this.setState({
            capturarProducto:true,
            nombreProd:'',
            codigoProd:'',
            texto:'',
            cantidad:0
        });
    }
    productoSel = (ins) =>{
        this.setState({
            codigoProd:ins.I,
            nombreProd:ins.N
        });
    }
    agregarProducto = () =>{
        if(this.state.cantidad <= 0)
           return;
        const det = {
            Cve:this.state.dato.Clave,
            Num:this.state.dato.Numero,
            Ren:0,
            Cod:this.state.codigoProd,
            Nom:this.state.nombreProd,
            Can:this.state.cantidad,
            Txt:this.state.texto
        };
        this.guardarProducto(det);
    }
    guardarProducto = async(prod) =>{
        const url = this.props.urlws +"/cliente/wsguardarDetallePedido";
        const json = JSON.stringify(prod);
        const resp = await fetch(url,{
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
          });
        const res = await resp.text();
        this.setState({cargando:false});
        if(res === "ok"){
            let det = this.state.detalle;
            if(prod.Ren === 0)
                det.push(prod);
            else{
                //ins[this.state.InsumoIndex].Nombre = eta.Nombre;
                //ins[this.state.InsumoIndex].Duracion = eta.Duracion;
            }
            this.setState({detalle:det,cantidad:0});
        }
        else
            Swal.fire('Atencion',res,'error');        
    }
    guardar = () =>{
        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: 'Confirme',
            text: "¿ Desea guardar estos datos ?",
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.value) {
                this.props.guardar(this.state.dato);
            }
          })        
    }
    render() {
        const estilo = { display: this.props.visible ? 'inline' : 'none'};
        if(this.props.dato === undefined)
            return null;
        if(Object.keys(this.props.dato).length === 0)
            return null;
        const estiloDet = { display: this.props.dato.Numero > 0 ? 'inline' : 'none'};
        return (
            <Container style={estilo}>
                <Form>
                    <div style={{width:900}}>
                        <div className="row">
                            <div className="col-1">
                                <FormGroup >
                                    <label>Numero</label>
                                    <Input size="sm" type="text" name="Numero" value={this.state.dato.Numero} style={{ width: 50 }} />
                                </FormGroup>
                            </div>
                            <div className="col-5">
                                <FormGroup>
                                    <label>Concepto</label>
                                    <Input size="sm" type="text" name="Concepto" value={this.state.dato.Concepto} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <label>Fecha</label>
                                    <Input size="sm" type="date" name="Fecha" value={this.state.dato.Fecha} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <FormGroup>
                                    <label>Fecha Entrega</label>
                                    <Input size="sm" type="date" name="FechaEnt" value={this.state.dato.FechaEnt} onChange={this.onChange} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <FormGroup >
                                    <label size="sm">Cliente</label>
                                    <Input size="sm" type="select" name="Cliente" value={this.state.dato.Cliente} onChange={this.onChange} >
                                        <option key={-1} value={-1}>Especifique</option>
                                        {this.props.clientes.map((i, k) => (
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup >
                                    <label size="sm">Tipo de envio</label>
                                    <Input size="sm" type="select" name="TipoEnvio" value={this.state.dato.TipoEnvio} onChange={this.onChange} >
                                        <option key={-1} value={-1}>Especifique</option>
                                        {this.props.tiposEnvio.map((i, k) => (
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <FormGroup >
                                    <label size="sm">Responsable de Venta</label>
                                    <Input size="sm" type="select" name="RespVta" value={this.state.dato.RespVta} onChange={this.onChange} >
                                        <option key={-1} value={-1}>Especifique</option>
                                        {this.props.usuarios.map((i, k) => (
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup >
                                    <label size="sm">Responsable de Envio</label>
                                    <Input size="sm" type="select" name="RespEnvio" value={this.state.dato.RespEnvio} onChange={this.onChange} >
                                        <option key={-1} value={-1}>Especifique</option>
                                        {this.props.usuarios.map((i, k) => (
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup >
                                    <label size="sm">Encargado de Taller</label>
                                    <Input size="sm" type="select" name="EncTaller" value={this.state.dato.EncTaller} onChange={this.onChange} >
                                        <option key={-1} value={-1}>Especifique</option>
                                        {this.props.usuarios.map((i, k) => (
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col">
                                <FormGroup >
                                    <label size="sm">Encargado de Produccion</label>
                                    <Input size="sm" type="select" name="EncProd" value={this.state.dato.EncProd} onChange={this.onChange} >
                                        <option key={-1} value={-1}>Especifique</option>
                                        {this.props.usuarios.map((i, k) => (
                                            <option key={i.I} value={i.I}>{i.N}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </Form>
                <br />
                <Button size="sm" color="primary" type="submit" onClick={this.guardar}>Aceptar</Button>
                {'  '}
                <Button size="sm" color="danger" onClick={this.props.onClose}>Cancelar</Button>
                <br/>
                <div style={estiloDet}>
                <h4>Productos   <Button color="primary" type="button" size="sm" onClick={this.nuevoProducto}>Agregar Producto</Button></h4>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Nombre</th>
                            <th style={{ width: 80 }}>Cantidad</th>
                            <th style={{ width: 80 }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.detalle.map((i, k) => (
                            <tr key={i.Ren}>
                                <td>{i.Cod}</td>
                                <td>{i.Nom}</td>
                                <td>{i.Can}</td>
                                <td>
                                    <Button color="primary" size="sm" onClick={() => this.editarEtapa(i, k)}><img src="/imagenes/pencil.png" alt="editar" title="editar" /></Button>
                                    &nbsp;
                                    <Button size="sm" color="danger">-</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                </div>
                <Modal id="dlgB" name="dlgB" isOpen={this.state.capturarProducto} toggle={this.handleClose} size="lg"  >
                    <ModalHeader >
                        <span>Productos</span>
                    </ModalHeader>
                    <ModalBody>
                        <Container >
                            <Productos height={200} width='98%' urlws={this.props.urlws} productoSel={this.productoSel}/>
                            <Form>
                                <div>
                                    <div className="row">
                                        <FormGroup as={Col-12} >
                                            <label size="sm"><b>Descripcion</b></label>
                                            <textarea class="form-control" rows="5" style={{width:'750px'}} name="texto" value={this.state.texto} onChange={this.onChange2}></textarea>
                                        </FormGroup>
                                    </div>
                                    <div className="row">
                                        <FormGroup as={Col} >
                                            <label size="sm"><b>Producto</b></label>
                                            <Input size="sm" type="text" value={this.state.nombreProd} style={{ width: 370 }} />
                                        </FormGroup>
                                        <FormGroup as={Col} >
                                            <label size="sm"><b>Cantidad</b></label>
                                            <Input size="sm" type="text" name="cantidad" value={this.state.cantidad} onChange={this.onChange2} style={{ width: 90 }} />
                                        </FormGroup>
                                        <FormGroup as={Col} >
                                            <br />
                                            <Button color="primary" type="button" onClick={this.agregarProducto} >+</Button>
                                        </FormGroup>
                                    </div>
                                </div>
                            </Form>
                        </Container>
                    </ModalBody>
                </Modal>             
            </Container>
        );
    }
}

class Productos extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            cargando:false,
            articulos:[],
            nombre:''
        };
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.cargarArticulos();
    }
    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value});
    }
    onBntBuscarClick = () =>{
        this.cargarArticulos();
    }    
    cargarArticulos = async () => {
        this.setState({cargando:true});
        const url = `${this.props.urlws}/articulo/produccion?filtro=${this.state.nombre}`;
        const resp = await fetch(url);
        const articulos = await resp.json();
        this.setState({cargando:false, articulos });
    }
    agregar = (ins) =>{
        Swal.fire({
            title: 'Cantidad',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: (cod) => {
              //this.cargarArticulo(cod);
            },
            allowOutsideClick: () => !Swal.isLoading()
          });
    }
    render(){
        return (
            <Container >
                {this.state.cargando ?
                <div className="loader">
                    <Spinner animation="border" color="primary"/>
                </div>
                : 
                null
                }
                <div>
                <Navbar bg="light" expand="lg">
                    <Collapse isOpen={true} id="basic-navbar-nav">
                        <Form inline onSubmit={this.onSubmit} >
                            <label size="sm">Nombre</label>&nbsp;
                            <Input size="sm" type="text" placeholder="Buscar" name="nombre" onChange={this.onChange} style={{width:400}} />
                            &nbsp;
                            <Button size="sm" color="success" onClick={this.onBntBuscarClick}>Buscar</Button>
                        </Form>
                    </Collapse>
                </Navbar>
                <div style={{ height: 4 }}></div>
                    <div style={{ height: this.props.height, width: this.props.width, overflow: 'auto' }}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th style={{width:45}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.articulos.map((esc, i) => (
                                    <tr key={i} onClick={()=> this.props.productoSel(esc)}>
                                        <td>{esc.N}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Container>
        );
    }
}