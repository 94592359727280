import React from 'react';
import { Nav, Navbar, NavLink,NavbarBrand } from 'reactstrap';
import Opciones from './Opciones';

export default class SideBarMenu extends React.Component {
    render() {
        return (
            <div>
                <Navbar bg="f5e831"  style={{height:'100%'}}>
                    <NavbarBrand></NavbarBrand>
                    <Nav>
                        <Opciones opcionSeleccionada={this.props.opcionSeleccionada} usuario={this.props.usuario}/>
                    </Nav>
                </Navbar>
            </div>
        );
    }
}