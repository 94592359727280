import React from 'react';
import { Nav, Navbar, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import SideBarMenu from './SideBarMenu';
import classnames from 'classnames';
import Menu from './Menu';
import Login from './Login';
import Insumos from './inv/Insumos';
import Materiales from './inv/Materiales';
import SubProductos from './inv/SubProductos';
import Productos from './inv/Productos';
import Kardex from './inv/Kardex';
import Entradas from './inv/Entradas';
import ReportesInv from './inv/ReportesInv';
import Trabajos from './prod/Trabajos';
import Formulas from './prod/Formulas';
import Proyectos from './prod/Proyectos';
import Proveedores from './cxp/Proveedores';
import CentrosTrabajo from './prod/CentrosTrabajo';
import Recursos from './prod/Recursos';
import Cortes from './prod/Cortes';
import Etapas from './prod/Etapas';
import Diario from './prod/Diario';
import Requisiciones from './compras/Requisiciones';
import Compras from './compras/Compras';
import XmlProvs from './compras/XmlProvs';
import PagosCxP from './cxp/PagosCxP';
import EdoCtaCxP from './cxp/EdoCtaCxP';
import CapturaEmpleado from './nom/CapturaEmpleado';
import ConsultaEmpleado from './nom/ConsultaEmpleado';
import Empleados from './nom/Empleados';
import Nominas from './nom/Nominas';
import Clientes from './cxc/Clientes';
import Pedidos from './ventas/Pedidos';
import Tiempos from './ventas/Tiempos';
import CotizaPedido from './ventas/CotizaPedido';

class App extends React.Component {
  constructor(props) {
    super(props);
    let urlws = '';
    //urlws = 'http://localhost:3211';
    this.state = {
      urlws: urlws,
      u: { id: 0, nombre: '', admin: false },
      lg: false,
      opc: 'formulas',
      activeTab:0,
      tabs:['Inicio'],
      componenteActivo:0,
      componentes: [
          <div><img src="/imagenes/logo.png"/></div>,
      ]
    }
  }
  opcionSeleccionada = (opc, txt) => {
    const rpt = opc;
    let urlws = this.state.urlws;
    for (let i = 0; i < this.state.tabs.length; i++) {
      if (this.state.tabs[i] === txt) {
        this.setState({ activeTab: i });
        return;
      }
    }
    if (opc === "cotizaciones")
       this.agregar(<CotizaPedido urlws={urlws} usuario={this.state.u} />);
    if (opc === "tiempos")
      this.agregar(<Tiempos urlws={urlws} usuario={this.state.u} />);
    if (opc === "pedidos")
      this.agregar(<Pedidos urlws={urlws} usuario={this.state.u} />);
    if (opc === "clientes")
      this.agregar(<Clientes urlws={urlws} usuario={this.state.u} />);
    if (opc === "insumos")
      this.agregar(<Insumos urlws={urlws} usuario={this.state.u} />);
    if (opc === "materiales")
      this.agregar(<Materiales urlws={urlws} usuario={this.state.u} />);
    if (opc === "subproductos")
      this.agregar(<SubProductos urlws={urlws} usuario={this.state.u} />);
    if (opc === "productos")
      this.agregar(<Productos urlws={urlws} usuario={this.state.u} />);
    if (opc === "trabajos")
      this.agregar(<Trabajos urlws={urlws} usuario={this.state.u} />);
    if (opc === "formulas")
      this.agregar(<Formulas urlws={urlws} usuario={this.state.u} />);
    if (opc === "proyectos")
      this.agregar(<Proyectos urlws={urlws} usuario={this.state.u} />);
    if (opc === "kardex")
      this.agregar(<Kardex urlws={urlws} usuario={this.state.u} />);
    if (opc === "entradas")
      this.agregar(<Entradas urlws={urlws} usuario={this.state.u} />);
    if (opc === "inventario")
      this.agregar(<ReportesInv urlws={urlws} usuario={this.state.u} />);
    if (opc === "proveedores")
      this.agregar(<Proveedores urlws={urlws} usuario={this.state.u} />);
    if (opc === "centros")
      this.agregar(<CentrosTrabajo urlws={urlws} usuario={this.state.u} />);
    if (opc === "recursos")
      this.agregar(<Recursos urlws={urlws} usuario={this.state.u} />);
    if (opc === "cortes")
      this.agregar(<Cortes urlws={urlws} usuario={this.state.u} />);
    if (opc === "etapasProd")
      this.agregar(<Etapas urlws={urlws} usuario={this.state.u} />);
    if (opc === "diario")
      this.agregar(<Diario urlws={urlws} usuario={this.state.u} />);
    if (opc === "req")
      this.agregar(<Requisiciones urlws={urlws} usuario={this.state.u} />);
    if (opc === "compras")
      this.agregar(<Compras urlws={urlws} usuario={this.state.u} />);
    if (opc === "xmlProvs")
      this.agregar(<XmlProvs urlws={urlws} usuario={this.state.u} />);
    if (opc === "edoCxp")
      this.agregar(<EdoCtaCxP urlws={urlws} usuario={this.state.u} />);
    if (opc === "pagoscxp")
      this.agregar(<PagosCxP urlws={urlws} usuario={this.state.u} />);
    if (opc === "capnom")
      this.agregar(<CapturaEmpleado urlws={urlws} usuario={this.state.u} />);
    if (opc === "consnom")
      this.agregar(<ConsultaEmpleado urlws={urlws} usuario={this.state.u} />);
    if (opc === "nominas")
      this.agregar(<Nominas urlws={urlws} usuario={this.state.u} />);
    if (opc === "empleados")
      this.agregar(<Empleados urlws={urlws} usuario={this.state.u} />);
    this.setState({
        tabs: this.state.tabs.concat(txt)
    });
    opc = "";
    this.setState({ opc, txt });
  }
  iniciar = (u) => {
    const usu = { id: u.Id, nombre: u.Nombre, admin: u.Admin };
    this.setState({ lg: true, u: usu });
  }
  agregar = (componente) => {
    this.setState({
      activeTab:this.state.tabs.length,
      componentes: this.state.componentes.concat(componente)
    });
  };
  toggle = (i) =>{
    const txt = this.state.tabs[i];
    this.setState({activeTab:i, componenteActivo:i, txt});
  }
  cerrar = (i) =>{
    if(i == 0)
       return;
    //this.state.componentes.splice(i,1);
    //this.state.tabs.splice(i,1);
    this.state.componentes[i] = null;
    this.state.tabs[i] = null;
    this.setState({activeTab:this.state.tabs.length-1});
  }

  render() {
    let {opc} = this.state;
    let urlws = this.state.urlws;

    const estilo = { visibility: this.state.lg ? 'visible' : 'hidden' };
    return (
      <div>
        {this.state.lg ? null : <Login iniciar={this.iniciar} urlws={urlws} />}
        <div style={estilo}>
          <Navbar bg="dark" expand="lg">
            <NavbarBrand>
              <img src="/imagenes/logo.png" height="80" />
            </NavbarBrand>
            <Menu opcionSeleccionada={this.opcionSeleccionada} usuario={this.state.u} />
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span style={{ color: '#f00' }}>usuario:{this.state.u.nombre}</span>
          </Navbar>
          <div id="content" style={{ width: '90%' }}>
          <div>
              <Nav tabs>
                {this.state.tabs.map((o,i)=>
                  o == null ? null :
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === i })}
                      onClick={() => { this.toggle(i); }}>
                        <span>{o}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        { i > 0 &&
                        <a href="#"><b><span onClick={()=>{this.cerrar(i)}}><img src="/imagenes/circle-x.png" alt="cerrar"/></span></b></a>
                        }
                        </NavLink>
                  </NavItem>
                )}
                </Nav>
              </div>    
              {
              this.state.componentes.map((o,i) => {
                const k = "k" + i;
                const visible = i == this.state.activeTab ?'block':'none';
                return <div key={k} style={{display:visible}}>{o}</div>
            })
            }
          </div>
        </div>
      </div>
    )
  }
}
export default App;