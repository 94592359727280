import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

export default class Menu extends React.Component {
    render() {
        let catInv = [{id:'insumos',nombre:'insumos'},{id:'materiales',nombre:'materiales'},{id:'subproductos',nombre:'subproductos'},{id:'productos',nombre:'productos'}];
        let movInv = [{id:'entradas',nombre:'entradas'},{id:'salidas',nombre:'salidas'},{id:'traspaso',nombre:'traspasos'}];
        let conInv = [{id:'kardex',nombre:'kardex por almacen'},{id:'kardexGen',nombre:'Kardex general'}];
        let repInv = [{id:'lstent',nombre:'Listado de entradas'},{id:'lstsal',nombre:'Listado de salidas'},{id:'sdosalm',nombre:'Saldos por almacen'},{id:'exisact',nombre:'Existencia actual'},{id:'repkardex',nombre:'Kardex'}];

        let catCom = [{id:'proveedores',nombre:'Proveedores'}];
        let movCom = [{id:'req',nombre:'Requisiciones de insumos'},{id:'compras',nombre:'Compras'},{id:'xmlProvs',nombre:'Recepcion de CFDIs'}];

        let catProd = [{id:'trabajos',nombre:'trabajos'},{id:'centros',nombre:'centros de trabajo'},{id:'recursos',nombre:'recursos'}]
        let movProd = [{id:'formulas',nombre:'Lista de materiales'},{id:'proyectos',nombre:'Proyectos'},{id:'etapasProd',nombre:'Avances de produccion'},{id:'diario',nombre:'diario de produccion'}];
        let conProd = [{id:'proystatus',nombre:'status de produccion'}];
        let repProd = [{id:'proy',nombre:'Listado de proyectos'},{id:'lstact',nombre:'Produccion en proceso'},{id:'lstact',nombre:'Ordenes de trabajo'}];

        let catCxc = [{id:'clientes',nombre:'Clientes'},{id:'cxcSectores',nombre:'Sectores'}];
        let movCxc = [{id:'pagoscxc',nombre:'Pago de clientes'}];
        let conCxc = [{id:'edoCxc',nombre:'Estado de cuenta'},{id:'conCxcPagos',nombre:'Listado de pagos'}];

        let catCxp = [{id:'proveedores',nombre:'Proveedores'}];
        let movCxp = [{id:'xmlProvs',nombre:'Recepcion de CFDIs'},{id:'pagoscxp',nombre:'Pago a proveedores'}];
        let conCxp = [{id:'edoCxp',nombre:'Estado de cuenta'},{id:'conCxpPagos',nombre:'Listado de pagos'}];

        let catNom = [{id:'empleados',nombre:'Empleados'},{id:'nomConceptos',nombre:'Conceptos'}];
        let movNom = [{id:'nominas',nombre:'Nominas'},{id:'capnom',nombre:'Nomina por empleado'}];
        let conNom = [{id:'consnom',nombre:'Calculo por empleado'}];

        let movVta = [{id:'pedidos',nombre:'Pedidos de clientes'},{id:'tiempos',nombre:'Disponibilidad y tiempos de entrega'},{id:'cotizaciones',nombre:'Cotizacion de Pedidos de clientes'},,{id:'facturacion',nombre:'Facturacion'}];
        let repVta = [{id:'rptPedCtes',nombre:'Listado de pedidos de clientes'},{id:'rptCotCtes',nombre:'Listado de cotizaciones'},,{id:'rptCotCtes',nombre:'Listado de ventas'}];

        let modulos = [
                       {id:"inv", nombre:"Inventario", catalogos:catInv, movimientos:movInv, consultas:conInv, reportes:repInv},
                       {id:"compras", nombre:"Compras", catalogos:catCom, movimientos:movCom, consultas:[], reportes:[]},
                       {id:"prod", nombre:"Produccion", catalogos:catProd, movimientos:movProd, consultas:conProd, reportes:repProd},
                       {id:"vtas", nombre:"Ventas", catalogos:[], movimientos:movVta, consultas:[], reportes:repVta},
                       {id:"cxc", nombre:"Clientes", catalogos:catCxc, movimientos:movCxc, consultas:conCxc, reportes:[]},
                       {id:"cxp", nombre:"Proveedores", catalogos:catCxp, movimientos:movCxp, consultas:conCxp, reportes:[]},
                       {id:"nom", nombre:"Nomina", catalogos:catNom, movimientos:movNom, consultas:conNom, reportes:[]},
                       {id:"conta", nombre:"Contabilidad", catalogos:[], movimientos:[], consultas:[], reportes:[]},

                    ];
    
        return (
            <ul className="nav">
                {modulos.map((e,i) =>{
                    return(
                        <li className="nav-item">
                            <Modulo modulo={e} opcionSeleccionada={this.props.opcionSeleccionada}/>
                        </li>    
                    )
                })}
                <a className="nav-link opcion" href="#">Configuracion</a>
            </ul>)
    }
}

class Modulo extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const catalogos = modulo.catalogos;
    const movimientos = modulo.movimientos;
    const consultas = modulo.consultas;
    const reportes = modulo.reportes;
    return (
      <div>
          <a  id={idMod} className="nav-link opcion" href="#">
          {titulo}
        </a>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={idMod} toggle={this.toggle} trigger="legacy" style={{width:400}}>
          <PopoverBody >
              <div className="container" style={{padding:1}}>
                <div className="body" style={{padding:5}}>
                  <div className="row">
                      <div className="col">
                        <span><b>catalogos</b></span>
                        <ul>
                            {catalogos.map((e,i) => <li data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                        <span><b>Movimientos</b></span>
                        <ul>
                            {movimientos.map((e,i) => <li data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                        <span><b>Consultas</b></span>
                        <ul>
                            {consultas.map((e,i) => <li data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                       </div>
                      <div className="col">
                        <span><b>Informes</b></span>
                        <ul>
                            {reportes.map((e,i) => <li data-opc={e.id} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                      </div>
                  </div>
                  </div>
              </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}